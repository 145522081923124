import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ProductType} from '../models/product-type';
import {ProductTypeResource} from '../resources/product-type.resource';
import {ProductTypeSetting} from '../models/product-type-setting';
import {Store} from '@ngrx/store';
import {ProductTypeSelectors, ProductTypeState} from '../store/selectors/product-type.selectors';
import {ProductTypeActions} from '../store/actions/product-type.actions';
import {Entity} from '../models/entity';

@Injectable({
  providedIn: 'root'
})
export class ProductTypeService {

  constructor(
    private productTypeResource: ProductTypeResource,
    private store: Store<ProductTypeState>
  ) { }

  public getProductTypes(): Observable<Entity<ProductType>[]> {
    this.store.dispatch(ProductTypeActions.Query());
    return this.store.select(ProductTypeSelectors.selectAll);
  }

  public getProductTypeSettings(productTypeId: string): Observable<ProductTypeSetting[]> {
    return this.productTypeResource.getProductTypeSettings(productTypeId);
  }

}
