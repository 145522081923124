import {Injectable, NgZone} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../services/authentication.service';
import {filter, map, take} from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              private ngZone: NgZone,
              private authService: AuthenticationService) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.getAuth().pipe(
      filter((auth) => !!auth.loaded),
      take(1),
      map((auth) => {
        if (!auth.isLoggedIn || auth.user.isAnonymous) {
          this.ngZone.run(() => this.router.navigateByUrl('/login')).then();
        }
        return auth.isLoggedIn;
      })
    );
  }
}
