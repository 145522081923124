import {Injectable} from '@angular/core';
import {from, Observable, ReplaySubject} from 'rxjs';
import {ResourceFormatter} from './resource-formatter.resource';
import {AngularFirestore, AngularFirestoreDocument, DocumentChangeAction} from '@angular/fire/firestore';
import {HttpClient} from '@angular/common/http';
import {Server} from '../models/server.model';
import {ServerToken} from '../../content/modules/server/models/server-token.model';
import {ServerStatus} from '../models/server-status.model';
import {environment} from '../../../environments/environment';
import {ServerUpdateAbleFields} from '../../content/modules/server/models/server-update-able-fields.model';
import {ServerVersion} from '../models/server-version.model';
import {AuthenticationService} from '../services/authentication.service';
import {map, mergeMap} from 'rxjs/operators';
import {CompanyService} from '../services/company.service';

@Injectable({
  providedIn: 'root'
})
export class ServerResource {

  server: Observable<Server>;

  serverTokens: { [server_id: string]: ServerToken } = {};

  constructor(
    private http: HttpClient,
    private firestore: AngularFirestore,
    private auth: AuthenticationService,
    private formatter: ResourceFormatter,
    private companyService: CompanyService
  ) {
  }

  async getServerToken(id): Promise<string> {
    if (this.serverTokens[id] === undefined || new Date(this.serverTokens[id].expires).getTime() < +new Date()) {
      const url = environment.backend.url + '/pufferd/oauth2/token/generate';
      const token = await this.auth.getAuth().getValue().user.getIdToken();
      const response = (await this.http.post(url, {serverIds: [id], idToken: token}).toPromise()) as {[serverId: string]: ServerToken};
      this.serverTokens[id] = response[id];
    }
    return this.serverTokens[id].token;
  }

  getUserServers(userId: string) {
    const companyRef = this.companyService.getCurrentCompanyDocument();
    const userDoc = companyRef.collection('users').doc(userId).ref;
    return companyRef.collection<Server>('servers', ref => ref.where('user', '==', userDoc)).stateChanges();
  }

  getCompanyServers(companyId?: string) {
    let companyRef: AngularFirestoreDocument = null;

    if (companyId) {
      companyRef = this.firestore.collection('companies').doc(companyId);
    } else {
      companyRef = this.companyService.getCurrentCompanyDocument();
    }

    return companyRef.collection<Server>('servers', ref => ref.where('company', '==', companyRef.ref)).stateChanges();
  }

  public renewServers(serverIds: string[], companyId: string) {
    const data = {
      serverIds: serverIds,
      companyId: companyId
    };
    return this.http.post(environment.backend.url + '/payment/renew', data);
  }

  getServerStatus(daemonUrl, id: string): Observable<ServerStatus> {
    return from(this.getServerToken(id)).pipe(
      mergeMap(serverToken => {
        return this.http.get<ServerStatus>('http://' + daemonUrl + '/pufferd/proxy/server/' + id + '/status', {
          headers: {
            'Authorization': 'Bearer ' + serverToken
          }
        });
      }),
      map((serverStatus) => {
        serverStatus.serverId = id;
        return serverStatus;
      })
    );
  }

  getConsoleById(daemonUrl: string, id: string): Observable<any> {
    const subject = new ReplaySubject();

    this.getServerToken(id).then(token => {
      daemonUrl = daemonUrl.replace(/^(http|https):\/\//g, '');
      const url = 'ws://' + daemonUrl + '/pufferd/proxy/server/' + id + '/console?accessToken=' + token;
      const socket = new WebSocket(url);
      socket.onmessage = (event: MessageEvent) => {
        subject.next(event.data);
        if (subject.observers.length === 0) {
          subject.complete();
          socket.close();
        }
      };
    });


    return subject.asObservable();
  }

  updateServer(id: string, data: ServerUpdateAbleFields): Promise<void> {
    const companyRef = this.companyService.getCurrentCompanyDocument();

    return companyRef.collection('servers').doc(id).update(data);
  }

  async updateServerStatus(daemonUrl: string, id: string, status: string): Promise<any> {
    return await this.http.get('http://' + daemonUrl + '/pufferd/proxy/server/' + id + '/' + status, {
      headers: {
        'Authorization': 'Bearer ' + await this.getServerToken(id)
      }
    }).toPromise();
  }

  async sendCommand(daemonUrl: string, id: string, command: string): Promise<any> {
    return await this.http.post('http://' + daemonUrl + '/pufferd/proxy/server/' + id + '/console', command, {
      headers: {
        'Authorization': 'Bearer ' + await this.getServerToken(id)
      }
    }).toPromise();
  }

  getServerVersions(): Observable<DocumentChangeAction<ServerVersion>[]> {
    return this.firestore.collection<ServerVersion>('versions', ref => ref.orderBy('version', 'desc')).stateChanges();
  }

  getServerVersion(versionId: string): Observable<ServerVersion> {
    return this.formatter.doc('versions/' + versionId);
  }

  updateServerVersion(serverId: string, versionPath: string) {
    const companyRef = this.companyService.getCurrentCompanyDocument();

    return companyRef.collection('servers').doc(serverId).update({version: this.firestore.doc(versionPath).ref});
  }

  cancelServer(serverId: string) {
    const companyRef = this.companyService.getCurrentCompanyDocument();

    return companyRef.collection('servers').doc(serverId).update({state: 3});
  }
}
