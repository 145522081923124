import {Injectable} from '@angular/core';
import {Action, AngularFirestore, DocumentChangeAction, DocumentSnapshot} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {Invoice} from '../models/invoice';
import {CompanyService} from '../services/company.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceResource {

  constructor(
    private firestore: AngularFirestore,
    private companyService: CompanyService
  ) {}

  getInvoicesByUserId(userId): Observable<DocumentChangeAction<Invoice>[]> {
    const companyRef = this.companyService.getCurrentCompanyDocument();

    const userRef = companyRef.collection('users').doc(userId).ref;
    return companyRef.collection<Invoice>('invoices', ref => ref.where('user', '==', userRef).orderBy('due', 'desc'))
      .stateChanges();
  }

  getInvoiceById(invoiceId): Observable<Action<DocumentSnapshot<Invoice>>> {
    const companyRef = this.companyService.getCurrentCompanyDocument();

    return companyRef.collection('invoices').doc<Invoice>(invoiceId).snapshotChanges();
  }

  getInvoicesByCompanyId(): Observable<DocumentChangeAction<Invoice>[]> {
    const companyRef = this.companyService.getCurrentCompanyDocument();

    return companyRef.collection<Invoice>('invoices', ref => ref.orderBy('due', 'desc'))
      .stateChanges();
  }

  cancelInvoice(invoiceId) {
    const companyRef = this.companyService.getCurrentCompanyDocument();
    return companyRef.collection('invoices').doc<Partial<Invoice>>(invoiceId).update({state: 3});
  }


}
