import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {ServerVersionActions} from '../actions/server-version.actions';
import {ServerVersion} from '../../models/server-version.model';
import {Entity} from '../../models/entity';

export module ServerVersionReducers {
  export type EntityType = Entity<ServerVersion>;

  // Entity adapter
  export const adapter = createEntityAdapter<EntityType>();

  export interface State extends EntityState<EntityType> { }


  export const initialState: State = adapter.getInitialState();

  // Reducer
  export function reducers(
    state: State = initialState,
    action: ServerVersionActions.Actions) {

    switch (action.type) {

      case ServerVersionActions.ADDED:
        return adapter.addOne(action.payload, state);

      case ServerVersionActions.MODIFIED:
        return adapter.updateOne({
          id: action.payload.ref.id,
          changes: action.payload
        }, state);

      case ServerVersionActions.REMOVED:
        return adapter.removeOne(action.payload.ref.id, state);

      default:
        return state;
    }
  }
}
