import {createFeatureSelector, createSelector} from '@ngrx/store';
import {State} from '../../utils/abstract-reducers';
import {Entity} from '../../models/entity';
import {AuthUser} from '../../models/auth-user';
import {AuthUserReducers} from '../reducers/auth-user.reducers';

export module AuthUserSelectors {
  export const getState = createFeatureSelector<AuthUserState>('authUser');

  // Entity selectors
  export const {
    selectEntities,
    selectAll,
    selectTotal,
    selectIds,
  } = AuthUserReducers.adapter.getSelectors(getState);

  const getEntityById = (id: string) => (state: AuthUserState) => state.entities[id];
  export const getAuthUserById = (id: string) => createSelector(getState, getEntityById(id));
}

export type AuthUserState = State<Entity<AuthUser>>;
