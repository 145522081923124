import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable, zip} from 'rxjs';
import {Action, Store} from '@ngrx/store';
import {map, mergeMap, switchMap, takeUntil} from 'rxjs/operators';
import {ServerResource} from '../../resources/server.resource';
import {ServerStatusActions} from '../actions/server-status.actions';
import {ServerService} from '../../services/server.service';
import {ServerStatusReducers} from '../reducers/server-status.reducers';
import {ServerStatusSelectors} from '../selectors/server-status.selectors';
import {StoreService} from '../../services/store.service';

@Injectable()
export class ServerStatusEffects {


  @Effect()
  query$: Observable<Action> = this.actions$.pipe(
    ofType(ServerStatusActions.QUERY),
    map((action: ServerStatusActions.Query) => action),
    mergeMap(data => {
      return zip(
        this.store.select(ServerStatusSelectors.getServerStatusById(data.id)),
        this.serverResource.getServerStatus(data.daemonUrl, data.id),
      );
    }),
    map(action => {
      const oldStatus = action[0];
      const status = action[1];
      let queryType = 'added';

      if (oldStatus) {
        queryType = 'modified';
      }

      return {
        type: `[ServerStatus] ${queryType}`,
        payload: {id: status.serverId, ...status},
      };
    }),
    takeUntil(this.storeService.onComplete(ServerStatusActions.QUERY))
  );


  @Effect()
  update$: Observable<Action> = this.actions$.pipe(
    ofType(ServerStatusActions.UPDATE),
    map((action: ServerStatusActions.Update) => action),
    switchMap(data => {
      return this.serverResource.updateServerStatus(data.payload.ip, data.id, data.payload.newStatus);
    }),
    map(() => new ServerStatusActions.Success())
  );

  constructor(
    private actions$: Actions,
    private serverResource: ServerResource,
    private serverService: ServerService,
    private store: Store<ServerStatusReducers.State>,
    private storeService: StoreService
  ) {
  }
}
