import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {AngularFireAuth} from '@angular/fire/auth';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  isProgressing = false;
  returnUrl: string;

  fragmentSubscription: Subscription;

  registering = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AngularFireAuth,
    public snackbar: MatSnackBar,
    private authService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.fragmentSubscription = this.route.fragment.subscribe(fragment => {
      this.registering = fragment === 'register';
    });
  }

  ngOnDestroy(): void {
    this.fragmentSubscription.unsubscribe();
  }

  async login(form: NgForm) {
    const value = form.value;
    this.isProgressing = true;

    let response;

    try {
      response = await this.authService.login(value.email, value.password);
    } catch (e) {
      this.isProgressing = false;
      if (e instanceof HttpErrorResponse) {
        let err = e.error.error;
        if (typeof err !== 'string') {
          err = 'unkown error';
        }
        this.snackbar.open(err);
      }
      return;
    }

    this.isProgressing = false;
    if (response === 'Logged in') {
      this.router.navigateByUrl(this.returnUrl);
    }
    if (response) {
      this.snackbar.open(response, '', {duration: 3000});
    }
  }

  register() {
    this.router.navigate([], {fragment: 'register'});
  }
}
