import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ServerVersionReducers} from '../reducers/server-version.reducers';

export module ServerVersionSelectors {
  export const getState = createFeatureSelector<ServerVersionReducers.State>('serverVersions');

  // Entity selectors
  export const {
    selectEntities,
    selectAll,
    selectTotal,
  } = ServerVersionReducers.adapter.getSelectors(getState);

  const getEntityById = (id: string) => (state: ServerVersionReducers.State) => state.entities[id];
  export const getServerVersionById = (id: string) => createSelector(getState, getEntityById(id));

  export const getEntities = createSelector(
    getState,
    selectEntities,
  );
}


