import {NgModule} from '@angular/core';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {SweetAlertConfirmComponent} from './sweet-alert-confirm/sweet-alert-confirm.component';
import {SweetAlertComponent} from './sweet-alert/sweet-alert.component';
import {SweetAlertSuccessComponent} from './sweet-alert-success/sweet-alert-success.component';
import {CommonModule} from '@angular/common';
import {SweetAlertWarningComponent} from './sweet-alert-warning/sweet-alert-warning.component';

const DECLARATIONS = [
  SweetAlertComponent,
  SweetAlertConfirmComponent,
  SweetAlertSuccessComponent,
  SweetAlertWarningComponent,
];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
  imports: [
    SweetAlert2Module,
    CommonModule
  ],
})
export class SweetAlertModule {}
