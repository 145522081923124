import { Injectable } from '@angular/core';
import {ResourceFormatter} from './resource-formatter.resource';
import {AngularFirestore} from '@angular/fire/firestore';
import {catchError, map} from 'rxjs/operators';
import {Company} from '../models/company';
import {from, Observable} from 'rxjs';
import {Entity} from '../models/entity';

@Injectable({
  providedIn: 'root'
})
export class CompanyResource {

  constructor(
    private firestore: AngularFirestore
  ) { }

  public getCompanyByDomain(companyDomainUrl: string): Observable<Entity<Company>|null> {
    return this.firestore.collection<Company>('companies', ref => ref.where('domain', '==', companyDomainUrl).limit(1)).stateChanges().pipe(
      map(data => {
        if (data[0]) {
          const payload = data[0].payload;
          return {
            data: payload.doc.data(),
            ref: payload.doc.ref,
            id: payload.doc.id
          } as Entity<Company>;
        } else {
          return null;
        }
      }),
      catchError((err, caught) => {
        console.log(err);
        return caught;
      })
    );
  }

  public getCompanyById(companyId: string): Observable<Entity<Company>> {
    return this.firestore.collection('companies').doc<Company>(companyId).snapshotChanges().pipe(
      map(data => {
        return {
          ref: data.payload.ref,
          id: data.payload.id,
          data: data.payload.data()
        };
      })
    );
  }

  public saveCompany(companyId: string, data: Partial<Company>): Observable<void> {
    return from(this.firestore.collection('companies').doc(companyId).update(data));
  }

}
