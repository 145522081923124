import {Injectable} from '@angular/core';
import {ServerResource} from '../resources/server.resource';
import {Observable, timer} from 'rxjs';
import {ServerStatus} from '../models/server-status.model';
import {ServerUpdateAbleFields} from '../../content/modules/server/models/server-update-able-fields.model';
import {Store} from '@ngrx/store';
import {ServerReducers} from '../store/reducers/server.reducers';
import {ServerSelectors} from '../store/selectors/server.selectors';
import {ServerActions} from '../store/actions/server.actions';
import {ServerStatusSelectors} from '../store/selectors/server-status.selectors';
import {ServerStatusActions} from '../store/actions/server-status.actions';
import {ServerVersionReducers} from '../store/reducers/server-version.reducers';
import {ServerVersionSelectors} from '../store/selectors/server-version.selectors';
import {ServerVersionActions} from '../store/actions/server-version.actions';
import {ServerStatusReducers} from '../store/reducers/server-status.reducers';
import {mergeMap} from 'rxjs/operators';
import {AbstractService} from '../utils/abstract-service';
import {StoreService} from './store.service';

@Injectable({
  providedIn: 'root'
})
export class ServerService extends AbstractService<ServerReducers.State> {

  constructor(
    private serverResource: ServerResource,
    private serverStore: Store<ServerReducers.State>,
    private serverStatusStore: Store<ServerStatusReducers.State>,
    private serverVersionStore: Store<ServerVersionReducers.State>,
    private storeService: StoreService
  ) {
    super(serverStore, storeService);
  }

  public getServerToken(id): Promise<string> {
    return this.serverResource.getServerToken(id);
  }

  public getServers(): Observable<ServerReducers.EntityType[]> {
    this.dispatch(new ServerActions.Query());
    return this.serverStore.select(ServerSelectors.selectAll);
  }

  public getOpenServers(): Observable<ServerReducers.EntityType[]> {
    this.dispatch(new ServerActions.Query());
    return this.serverStore.select(ServerSelectors.getOpenServers());
  }

  public getServer(id: string): Observable<ServerReducers.EntityType> {
    this.dispatch(new ServerActions.Query());
    return this.serverStore.select(ServerSelectors.getServerById(id));
  }

  public getServerStatus(id: string, daemonUrl: string): Observable<ServerStatus> {
    return timer(0, 5000).pipe(
      mergeMap(() => {
        this.serverStatusStore.dispatch(new ServerStatusActions.Query(id, daemonUrl));
        return this.serverStatusStore.select(ServerStatusSelectors.getServerStatusById(id));
      })
    );
  }

  public renewServers(serverIds: string[], companyId: string) {
    return this.serverResource.renewServers(serverIds, companyId);
  }

  public getConsoleById(daemonUrl: string, serverId: string): Observable<any> {
    return this.serverResource.getConsoleById(daemonUrl, serverId);
  }

  public updateServer(serverId: string, data: ServerUpdateAbleFields): void {
    return this.serverStore.dispatch(new ServerActions.Update(serverId, data));
  }

  public updateServerStatus(daemonUrl, serverId: string, status): void {
    const payload = {ip: daemonUrl, newStatus: status};
    return this.serverStatusStore.dispatch(new ServerStatusActions.Update(serverId, payload));
  }

  public sendCommand(daemonUrl: string, serverId: string, command: string): Promise<object> {
    return this.serverResource.sendCommand(daemonUrl, serverId, command);
  }

  public getServerVersions(): Observable<ServerVersionReducers.EntityType[]> {
    this.dispatch(new ServerVersionActions.Query(), this.serverVersionStore);
    return this.serverVersionStore.select(ServerVersionSelectors.selectAll);
  }

  public getServerVersion(versionId: string): Observable<ServerVersionReducers.EntityType> {
    this.dispatch(new ServerVersionActions.Query(), this.serverVersionStore);
    return this.serverVersionStore.select(ServerVersionSelectors.getServerVersionById(versionId));
  }

  public updateServerVersion(serverId: string, versionPath: string) {
    return this.serverResource.updateServerVersion(serverId, versionPath);
  }

  public cancelServer(serverId: string) {
    return this.serverResource.cancelServer(serverId);
  }
}
