import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {ServerStatus} from '../../models/server-status.model';
import {ServerStatusActions} from '../actions/server-status.actions';

export module ServerStatusReducers {
  // Entity adapter
  export const adapter = createEntityAdapter<ServerStatus>();

  export interface State extends EntityState<ServerStatus> { }


  export const initialState: State = adapter.getInitialState();

// Reducer

  export function reducers(
    state: State = initialState,
    action: ServerStatusActions.Actions) {

    switch (action.type) {

      case ServerStatusActions.ADDED:
        return adapter.addOne(action.payload, state);

      case ServerStatusActions.MODIFIED:
        return adapter.updateOne({
          id: action.payload.serverId,
          changes: action.payload
        }, state);

      case ServerStatusActions.REMOVED:
        return adapter.removeOne(action.payload.serverId, state);

      default:
        return state;
    }
  }
}
