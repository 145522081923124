import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {AngularFireAuth} from '@angular/fire/auth';
import {Authentication} from '../models/authentication.model';
import {CompanyService} from './company.service';
import {ResourceFormatter} from '../resources/resource-formatter.resource';
import {UserService} from './user.service';
import {Entity} from '../models/entity';
import {AuthUser} from '../models/auth-user';
import {catchError, filter, switchMap, take, tap} from 'rxjs/operators';
import {AuthUserActions} from '../store/actions/user-auth.actions';
import {AuthUserSelectors, AuthUserState} from '../store/selectors/auth-user.selectors';
import {AbstractService} from '../utils/abstract-service';
import {Store} from '@ngrx/store';
import {StoreService} from './store.service';
import {User} from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends AbstractService<AuthUserState> {

  private auth: BehaviorSubject<Authentication>;

  constructor(
    private store: Store<AuthUserState>,
    private httpClient: HttpClient,
    private fireAuth: AngularFireAuth,
    private companyService: CompanyService,
    private formatter: ResourceFormatter,
    private userService: UserService,
    private storeService: StoreService
  ) {
    super(store, storeService);

    this.auth = new BehaviorSubject({
      isLoggedIn: false,
      user: null,
      loaded: false,
      user_token: ''
    });

    this.fireAuth.auth.onAuthStateChanged(async (user) => {
      await this.companyService.getCurrentCompanyAsync().pipe(
        take(1),
        switchMap(() => {
          return from(this.updateAuth(user));
        }),
        catchError(err => of(err)),
      ).toPromise();
    });
  }

  private async updateAuth(user: User) {
    const obj: Authentication = {
      loaded: true,
      user: user,
      isLoggedIn: user !== null
    };

    if (user !== null) {
      this.auth.next(obj);
      return;
    }

    const anonymouslyUser = await this.fireAuth.auth.signInAnonymously();
    obj.user = anonymouslyUser.user;
    await this.userService.setCompanyOnGuest(anonymouslyUser.user.uid);
    this.auth.next(obj);
  }

  getAuthUser(): Observable<Entity<AuthUser>> {
    return this.getAuth().pipe(
      filter(auth => !!auth.loaded && !!auth.isLoggedIn && !!auth.user),
      switchMap(auth => {
        this.dispatch(AuthUserActions.Query());
        return this.store.select(AuthUserSelectors.getAuthUserById(this.userUidToUserId(auth.user.uid)));
      })
    );
  }

  userUidToUserId(uid: string) {
    const parts = uid.split('/');
    return parts[parts.length - 1];
  }

  public getAuth(): BehaviorSubject<Authentication> {
    return this.auth;
  }

  public getCurrentAuth(): Authentication {
    return this.auth.getValue();
  }

  public getUserByAuthUid(): Observable<any> {
    const company = this.companyService.getCurrentCompany();

    return this.formatter.doc(`${company.ref.path}/users/${this.getCurrentAuth().user.uid}`);
  }

  public async login(username, password): Promise<string> {

    const companyId = await this.companyService.getCompanyIdByDomainName();
    if (!companyId) {
      return 'No company found';
    }

    const data = await this.formatter.get(this.httpClient.post(environment.backend.url + '/token', {companyId, username, password}));

    if (data['error'] === undefined) {
      await this.loginWithCustomToken(data['customToken'], data['userId']);

      return 'Logged in';

    } else {
      return data['error'];
    }
  }

  public async loginWithCustomToken(customToken: string, userId: string) {
    await this.fireAuth.auth.signInWithCustomToken(customToken);

    // Move cart from guest to user
    this.getAuthUser().pipe(
      take(1),
      tap( () => {
        this.userService.transferGuestCartToUser(userId);
      })
    ).subscribe();
  }

  public logout() {
    this.fireAuth.auth.signOut().then().catch(e => console.error(e));
    window.location.pathname = '/login';
  }
}
