import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {MapsAPILoader} from '@agm/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {take} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../../../environments/environment';
import {CompanyService} from '../../../../core/services/company.service';
import {AuthenticationService} from '../../../../core/services/authentication.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  form: FormGroup;

  // @ts-ignore
  autoCompleteService: google.maps.places.Autocomplete;

  @ViewChild('address', {static: false}) addressInput;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private companyService: CompanyService,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar,
  ) {
    this.form = formBuilder.group({
      full_name: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      phone: ['', Validators.required],
      address: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.mapsInit();
  }

  async mapsInit() {

    await this.mapsAPILoader.load();

    // @ts-ignore
    this.autoCompleteService = new google.maps.places.Autocomplete(this.addressInput.nativeElement);
  }

  get address() {
    // @ts-ignore
        const place: google.maps.places.PlaceResult = this.autoCompleteService.getPlace();
    return place.name;
  }

  async register() {
    if (!this.form.valid) {
      return;
    }
    const form = this.form.value;
    const company = await this.companyService.getCurrentCompanyAsync().pipe(take(1)).toPromise();

    try {
      const response = await this.httpClient.post<{customToken: string, userId: string}>(environment.backend.url + '/user/register', {
        email: form['email'],
        password: form['password'],
        companyId: company.id,
        metaData: {
          address: form['address'],
          name: form['full_name']
        }
      }).pipe(take(1)).toPromise();
      await this.authService.loginWithCustomToken(response.customToken, response.userId);
      this.snackBar.open('You have been registered', '', {
        duration: 3000
      });
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        console.error(e.error);
        const err = e.error;
        this.snackBar.open(err.error, '', {
          duration: 3000
        });
      }
    }

  }

}
