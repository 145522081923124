import { Component, OnInit } from '@angular/core';
import {SweetAlertComponent} from '../sweet-alert/sweet-alert.component';

@Component({
  selector: 'app-sweet-alert-success',
  templateUrl: './../sweet-alert/sweet-alert.component.html',
  styleUrls: [
    './../sweet-alert/sweet-alert.component.scss',
  ],
})
export class SweetAlertSuccessComponent extends SweetAlertComponent implements OnInit {

  constructor() {
    super();
    this.type = 'success';
  }

  ngOnInit() {
  }

}
