import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {LayoutConfigService} from '../../../../metronic/_base/layout';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit {

  // Public properties
  @Input() title: string;
  @Input() desc: string;
  @Input() data: { label: string; value: any }[];
  @ViewChild('chart', {static: true}) chart: ElementRef;

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   */
  constructor(private layoutConfigService: LayoutConfigService) {
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.initChartJS();
  }

  /** Init chart */
  initChartJS() {

    const chart = new Morris.Donut({
      // ID of the element in which to draw the chart.
      element: this.chart.nativeElement,
      // Chart data records -- each entry in this array corresponds to a point on
      // the chart.
      data: this.data,

      resize: true,
      colors: ['#5867dd', '#1dc9b7', '#ffb822', '#fd397a', '#9352EB', '#13D654', '#FFE414', '#FE562E']
    });
  }

}
