import {AbstractAction} from '../../utils/abstract-action';

export class SampleActions<T> extends AbstractAction<T> {

  private readonly _prefix: string;

  get prefix(): string {
    return this._prefix;
  }

  constructor(prefix: string) {
    super();
    this._prefix = prefix;
  }
}
