import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceStatus'
})
export class InvoiceStatusPipe implements PipeTransform {

  transform(value: number): string {
    const statuses = [{
      id: 0,
      name: 'unpaid',
    }, {
      id: 1,
      name: 'paid'
    }, {
      id: 2,
      name: 'overdue'
    }, {
      id: 3,
      name: 'cancelled'
    }, {
      id: 4,
      name: 'refunded'
    }];

    const status = statuses.find(_status => _status.id === value);

    if (status) {
      return status.name;
    }
    return '';
  }

}
