import {Injectable} from '@angular/core';
import {Actions} from '../utils/abstract-action';
import {Store} from '@ngrx/store';
import {Observable, ReplaySubject} from 'rxjs';
import {filter} from 'rxjs/internal/operators/filter';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  private _dispatches: string[] = [];

  private completeBehavior = new ReplaySubject<string>();

  constructor() { }

  public dispatch<T, K = any>(action: Actions<T>, store: Store<K>) {
    if (this._dispatches.indexOf(action.type) !== -1) {
      return;
    }
    this._dispatches.push(action.type);
    store.dispatch(action);
  }

  public onComplete(action: string): Observable<string> {
    return this.completeBehavior.pipe(
      filter(v => v === action)
    );
  }

  public complete(action: string) {
    const dispatchIndex = this._dispatches.indexOf(action);
    if (dispatchIndex === -1) {
      return;
    }

    this.completeBehavior.next(action);
    this._dispatches = this._dispatches.slice(dispatchIndex, dispatchIndex + 1);
  }

  get dispatches(): string[] {
    return this._dispatches;
  }
}
