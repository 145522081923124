import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ProductReducers} from '../reducers/product.reducers';
import {State} from '../../utils/abstract-reducers';
import {Product} from '../../models/product';
import {Entity} from '../../models/entity';

export module ProductSelectors {
  export const getState = createFeatureSelector<ProductState>('products');

  // Entity selectors
  export const {
    selectEntities,
    selectAll,
    selectTotal,
    selectIds,
  } = ProductReducers.adapter.getSelectors(getState);

  const getEntityById = (id: string) => (state: ProductState) => state.entities[id];
  export const getProductById = (id: string) => createSelector(getState, getEntityById(id));
}

export type ProductState = State<Entity<Product>>;
