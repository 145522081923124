import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {CoreModule, HttpLoaderFactory} from './core/core.module';
import {LayoutModule} from './content/layout/layout.module';
import {AppRoutingModule} from './app-routing.module';
import {AuthenticationService} from './core/services/authentication.service';
import {StoreModule} from '@ngrx/store';
import {appReducers} from './core/store/reducers/app.reducers';
import {EffectsModule} from '@ngrx/effects';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {ServerEffects} from './core/store/effects/server.effects';
import {ServerVersionEffects} from './core/store/effects/server-version.effects';
import {ServerStatusEffects} from './core/store/effects/server-status.effects';
import {ProductEffects} from './core/store/effects/product.effects';
import {ProductTypeEffects} from './core/store/effects/product-type.effects';
import {AuthUserEffects} from './core/store/effects/auth-user.effects';
import {CartEffects} from './core/store/effects/cart.effects';
import {UserStore} from './core/store/stores/user-store';
import {UserRoleStore} from './core/store/stores/user-role-store';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {AgmCoreModule} from '@agm/core';
import {ModulesModule} from './content/modules/modules.module';
import {InvoiceEffects} from './core/store/effects/invoice.effects';
import {registerLocaleData} from '@angular/common';
import localeDa from '@angular/common/locales/da';
import {UserPrivateDataEffects} from './core/store/effects/user-private-data.effects';
import {PartialsModule} from './metronic/partials/partials.module';
import {MatProgressSpinnerModule} from '@angular/material';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {FrontPageEffects} from './core/store/effects/front-page.effects';
import {ToastrModule} from 'ngx-toastr';

registerLocaleData(localeDa, 'dk');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    LayoutModule,

    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    ModulesModule,
    PartialsModule,

    ToastrModule.forRoot(),
    SweetAlert2Module.forRoot(),
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({stateKey: 'router'}),
    StoreDevtoolsModule.instrument({
      maxAge: 5,
      logOnly: environment.production
    }),
    EffectsModule.forFeature([
      ServerEffects,
      ServerStatusEffects,
      ServerVersionEffects,
      ProductEffects,
      InvoiceEffects,
      CartEffects,
      ProductTypeEffects,
      FrontPageEffects,
      AuthUserEffects,
      UserStore.effects(),
      UserRoleStore.effects(),
      UserPrivateDataEffects,
    ]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.agm.key,
      libraries: ['places']
    }),
    MatProgressSpinnerModule
  ],
  providers: [
    AuthenticationService,
    {
      provide: LOCALE_ID,
      useValue: 'dk'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
