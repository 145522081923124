// Angular
import {AfterViewInit, Component, OnInit} from '@angular/core';
// Layout
import {HtmlClassService} from '../html-class.service';
import {CompanyService} from '../../../../core/services/company.service';
import {Observable} from 'rxjs';
import {Entity} from '../../../../core/models/entity';
import {Company} from '../../../../core/models/company';

@Component({
  selector: 'kt-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss']
})
export class BrandComponent implements OnInit, AfterViewInit {

  $company: Observable<Entity<Company>>;

  constructor(
    public htmlClassService: HtmlClassService,
    public companyService: CompanyService,
  ) {
  }

  ngOnInit(): void {
    this.$company = this.companyService.getCurrentCompanyAsync();
  }

  ngAfterViewInit(): void {
  }
}
