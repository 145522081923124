import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ServerReducers} from '../reducers/server.reducers';
import {hasOwnProperty} from 'tslint/lib/utils';

export module ServerSelectors {
  export const getServerState = createFeatureSelector<ServerReducers.State>('servers');

  // Entity selectors
  export const {
    selectEntities,
    selectAll,
    selectTotal,
    selectIds,
  } = ServerReducers.adapter.getSelectors(getServerState);

  const getEntityById = (id: string) => (state: ServerReducers.State) => state.entities[id];
  export const getServerById = (id: string) => createSelector(getServerState, getEntityById(id));
  export const getOpenServers = () => createSelector(getServerState, (state: ServerReducers.State) => {
    const openServers = [];
    for (const key in state.entities) {
      if (state.entities[key].data.state === 1) {
        openServers.push(state.entities[key]);
      }
    }
    return openServers;
  });
}


