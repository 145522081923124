import {Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Authentication} from '../../../core/models/authentication.model';
import {AuthenticationService} from '../../../core/services/authentication.service';
import {CompanyService} from '../../../core/services/company.service';

@Component({
  selector: 'app-frontpage-layout',
  templateUrl: './frontpage-layout.component.html'
})
export class FrontpageLayoutComponent implements OnInit {

  auth: BehaviorSubject<Authentication>;
  companyLoaded = false;

  public constructor(
    private authService: AuthenticationService,
    private companyService: CompanyService,
  ) { }

  ngOnInit() {
    this.auth = this.authService.getAuth();
    this.companyService.getCompanyIdByDomainName().then(res => {
      this.companyLoaded = true;
    });
  }

}
