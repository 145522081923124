import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {LoadingComponent} from './components/loading/loading.component';
import {CoreModule} from '../../core/core.module';
import {FrontpageLayoutComponent} from './frontpage-layout/frontpage-layout.component';
import {TranslateModule} from '@ngx-translate/core';
import {LoginComponent} from './components/login/login.component';
import {RegisterComponent} from './components/register/register.component';
import {AgmCoreModule} from '@agm/core';
import {InvoiceComponent} from './components/invoice/invoice.component';
import {HeaderFrontpageComponent} from './frontpage-layout/header-frontpage/header-frontpage.component';
import {MetronicModule} from '../../metronic/metronic.module';
import {ThemeModule} from './dashboard-metronic-layout/theme.module';
import {PartialsModule} from '../../metronic/partials/partials.module';
import {DoughnutChartComponent} from './components/doughnut-chart/doughnut-chart.component';
import {DoughnutBulletpointsComponent} from './components/doughnut-chart/components/doughnut-bulletpoints/doughnut-bulletpoints.component';
import {InvoiceListComponent} from './components/invoice-list/invoice-list.component';
import {MatTableModule} from '@angular/material';
import {SweetAlertModule} from './components/sweet-alert/sweet-alert.module';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {ComingSoonOverlayComponent} from './components/coming-soon-overlay/coming-soon-overlay.component';
import {CkeditorClassicComponent} from './components/ckeditor-classic/ckeditor-classic.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';

const DECLARATIONS = [

  // Components
  DoughnutChartComponent,
  DoughnutBulletpointsComponent,
  InvoiceComponent,
  LoadingComponent,
  LoginComponent,
  RegisterComponent,
  InvoiceListComponent,
  ComingSoonOverlayComponent,
  CkeditorClassicComponent,

  // Frontpage layout
  FrontpageLayoutComponent,

  // Components
  HeaderFrontpageComponent,
  // End Frontpage layout
];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
  providers: [
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    MetronicModule,
    ThemeModule,
    PartialsModule,

    // Google Materials
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatCardModule,
    MatSnackBarModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    TranslateModule,
    AgmCoreModule,
    MatAutocompleteModule,
    MatTableModule,
    SweetAlertModule,
    SweetAlert2Module,
    CKEditorModule
  ],
})
export class LayoutModule {}
