import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ServerStatusReducers} from '../reducers/server-status.reducers';

export module ServerStatusSelectors {
  export const getServerStatusState = createFeatureSelector<ServerStatusReducers.State>('serverStatus');

  // Entity selectors
  export const {
    selectEntities,
    selectAll,
    selectTotal,
    selectIds,
  } = ServerStatusReducers.adapter.getSelectors(getServerStatusState);

  const getEntityById = (id: string) => (state: ServerStatusReducers.State) => state.entities[id];
  export const getServerStatusById = (id: string) => createSelector(getServerStatusState, getEntityById(id));
}


