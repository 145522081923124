import {User} from '../../models/user';
import {AbstractAction} from '../../utils/abstract-action';
import {Injectable, Type} from '@angular/core';
import {AbstractEffects} from '../../utils/abstract-effects';
import {Actions, Effect} from '@ngrx/effects';
import {UserResource} from '../../resources/user.resource';
import {Observable} from 'rxjs';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {Action} from '@ngrx/store';
import {AbstractStore} from '../../utils/abstract-store';
import {StoreService} from '../../services/store.service';

class CUserStore extends AbstractStore<User> {

  prefix(): string {
    return 'User';
  }

  effects(): Type<AbstractEffects<User>> {
    return UserEffects;
  }

}

@Injectable()
class UserEffects extends AbstractEffects<User> {

  constructor(protected actions$: Actions, private userResource: UserResource, storeService: StoreService) {
    super(actions$, storeService);
  }

  getResources(): Observable<DocumentChangeAction<User>[]> {
    return this.userResource.getUsers();
  }

  get prefix(): string {
    return 'User';
  }

  @Effect()
  get query$(): Observable<Action> {
    return this._query$;
  }

  protected get actions(): AbstractAction<User> {
    return UserStore.actions();
  }
}


export const UserStore = new CUserStore();
