import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Entity} from '../../models/entity';
import {CartProduct} from '../../models/cart-product';
import {CartActions} from '../actions/cart.actions';

export module CartReducers {
  export type EntityType = Entity<CartProduct>;

  // Entity adapter
  export const adapter = createEntityAdapter<EntityType>();

  export interface State extends EntityState<EntityType> { }


  export const initialState: State = adapter.getInitialState();

  // Reducer
  export function reducers(
    state: State = initialState,
    action: CartActions.Actions) {

    switch (action.type) {

      case CartActions.ADDED:
        return adapter.addOne(action.payload, state);

      case CartActions.MODIFIED:
        return adapter.updateOne({
          id: action.payload.ref.id,
          changes: action.payload
        }, state);

      case CartActions.REMOVED:
        return adapter.removeOne(action.payload.ref.id, state);

      default:
        return state;
    }
  }
}
