import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {ProductSelectors, ProductState} from '../store/selectors/product.selectors';
import {ProductActions} from '../store/actions/product.actions';
import {Product} from '../models/product';
import {ProductResource} from '../resources/product.resource';
import {Entity} from '../models/entity';
import {AbstractService} from '../utils/abstract-service';
import {StoreService} from './store.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends AbstractService<ProductState> {

  constructor(
    private store: Store<ProductState>,
    private productResource: ProductResource,
    private storeService: StoreService
  ) {
    super(store, storeService);
  }

  getProducts(): Observable<Entity<Product>[]> {
    this.dispatch(ProductActions.Query());
    return this.store.select(ProductSelectors.selectAll);
  }

  getProduct(productId: string): Observable<Entity<Product>> {
    this.dispatch(ProductActions.Query());
    return this.store.select(ProductSelectors.getProductById(productId));
  }

  editProduct(productId: string, changes: Partial<Product>|Product) {
    this.store.dispatch(ProductActions.Update(productId, changes));
  }

  addProduct(product: Product): Promise<void> {
    return this.productResource.editProduct('', product);
  }

}
