import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {filter, map, mergeMap, switchMap, takeUntil} from 'rxjs/operators';
import {ServerActions} from '../actions/server.actions';
import {ServerResource} from '../../resources/server.resource';
import {Entity} from '../../models/entity';
import {Server} from '../../models/server.model';
import {AuthenticationService} from '../../services/authentication.service';
import {StoreService} from '../../services/store.service';

@Injectable()
export class ServerEffects {


  @Effect()
  query$: Observable<Action> = this.actions$.pipe(
    ofType(ServerActions.QUERY),
    switchMap(action => {
      return this.authentication.getAuthUser();
    }),
    filter(auth => !!auth.data),
    switchMap(auth => {
      const user = auth.data;
      const permissions = user.role.permissions;

      if (permissions.indexOf('server.company') >= 0) {
        return this.serverResource.getCompanyServers();
      } else {
        return this.serverResource.getUserServers(auth.id);
      }
    }),
    mergeMap(actions => actions),
    map(action => {
      return {
        type: `[Server] ${action.type}`,
        payload: Entity.fromSnapshot<Server>(action.payload.doc)
      };
    }),
    takeUntil(this.storeService.onComplete(ServerActions.QUERY))
  );

  @Effect()
  update$: Observable<Action> = this.actions$.pipe(
    ofType(ServerActions.UPDATE),
    map((action: ServerActions.Update) => action),
    switchMap(data => {
      return this.serverResource.updateServer(data.id, data.changes);
    }),
    map(() => new ServerActions.Success())
  );

  constructor(
    private actions$: Actions,
    private serverResource: ServerResource,
    private authentication: AuthenticationService,
    private storeService: StoreService
  ) { }
}
