import {State} from '../../utils/abstract-reducers';
import {UserPrivateDataActions} from '../actions/user-private-data.actions';
import {UserPrivateData} from '../../models/user-private-data';
import {AbstractAction, Added} from '../../utils/abstract-action';
import {Entity} from '../../models/entity';
import {createEntityAdapter, EntityState} from '@ngrx/entity';

class CUserPrivateDataReducers {

  public readonly _adapter;

  private readonly _initialState: State<UserPrivateData>;

  constructor(
    public actions: AbstractAction<UserPrivateData>
  ) {
    this._adapter = createEntityAdapter<Entity<UserPrivateData>>({
      selectId: this.selectUserId
    });
    this._initialState = this._adapter.getInitialState();
  }

  selectUserId(user: any): string {
    return user.ref.parent.parent.id;
  }

  public reducers = (state?: State<UserPrivateData>, action?: Added<Entity<UserPrivateData>>) => {
    if (!state) {
      state = this.initialState;
    }

    if (!action) {
      return state;
    }

    if (action.type === this.actions.VALUE) {
      if ((state.ids as any).find(id => id === this.selectUserId(action.payload))) {
        return this._adapter.updateOne({
          id: this.selectUserId(action.payload),
          changes: action.payload
        }, state);
      }
      return this._adapter.addOne(action.payload, state);
    } else {
      return state;
    }
  }

  get adapter() {
    return this._adapter;
  }

  get initialState(): State<UserPrivateData> {
    return this._initialState;
  }

}

export interface State<T> extends EntityState<T> {
}

export const UserPrivateDataReducers = new CUserPrivateDataReducers(UserPrivateDataActions);
