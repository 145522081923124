import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {map, switchMap} from 'rxjs/operators';
import {ProductActions} from '../actions/product.actions';
import {ProductResource} from '../../resources/product.resource';
import {Product} from '../../models/product';
import {AbstractAction, Update} from '../../utils/abstract-action';
import {AbstractEffects} from '../../utils/abstract-effects';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {StoreService} from '../../services/store.service';

@Injectable()
export class ProductEffects extends AbstractEffects<Product> {

  @Effect()
  update$: Observable<Action> = this.actions$.pipe(
    ofType(ProductActions.UPDATE),
    map((action: Update<Product>) => action),
    switchMap(data => {
      return this.productResource.editProduct(data.id, data.changes);
    }),
    map(() => ProductActions.Success())
  );


  constructor(protected actions$: Actions, private productResource: ProductResource, storeService: StoreService) {
    super(actions$, storeService);
  }

  getResources(): Observable<DocumentChangeAction<Product>[]> {
    return this.productResource.getProducts();
  }

  get prefix(): string {
    return 'Product';
  }

  @Effect()
  get query$(): Observable<Action> {
    return this._query$;
  }

  protected get actions(): AbstractAction<Product> {
    return ProductActions;
  }
}
