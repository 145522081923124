import {AbstractAction} from '../../utils/abstract-action';
import {UserPrivateData} from '../../models/user-private-data';
import {Action} from '@ngrx/store';

export module UserPrivateDataCustomActions {
  export class CustomQuery implements Action {
    constructor(public userId: string, public readonly type: string) {}
  }
}

class CUserPrivateDataActions extends AbstractAction<UserPrivateData> {
  get prefix(): string {
    return 'UserPrivateData';
  }

  Query(userId?): UserPrivateDataCustomActions.CustomQuery {
    return new UserPrivateDataCustomActions.CustomQuery(userId, this.QUERY);
  }
}


export const UserPrivateDataActions = new CUserPrivateDataActions();

