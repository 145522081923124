import {AbstractAction, Added, Modified, Query, Removed, Success, Update} from '../../utils/abstract-action';
import {Invoice} from '../../models/invoice';
import {Action} from '@ngrx/store';
import {Entity} from '../../models/entity';

class CInvoiceActions extends AbstractAction<Invoice> {
  get prefix(): string {
    return 'Invoice';
  }

  public get QUERYUSERID(): string {
    return '[' + this.prefix + '] query userId';
  }

  public get QUERYINVOICEID(): string {
    return '[' + this.prefix + '] query invoiceId';
  }

  public get QUERYCOMPANYID(): string {
    return '[' + this.prefix + '] query companyId';
  }

  public QueryUserId(userId: string): Query {
    return new QueryUserId(userId, this.QUERYUSERID);
  }

  public QueryInvoiceId(invoiceId: string): Query {
    return new QueryInvoiceId(invoiceId, this.QUERYINVOICEID);
  }

  public QueryCompanyId(): Query {
    return new QueryCompanyId(this.QUERYCOMPANYID);
  }
}


export class QueryUserId implements Action {
  constructor(public userId: string, public readonly type) {
  }
}

export class QueryInvoiceId implements Action {
  constructor(public invoiceId: string, public readonly type) {
  }
}

export class QueryCompanyId implements Action {
  constructor(public readonly type) {
  }
}

export type Actions<T> =
  QueryUserId
  | QueryInvoiceId
  | QueryCompanyId
  | Added<Entity<T>>
  | Modified<Entity<T>>
  | Removed<Entity<T>>
  | Update<T>
  | Success;

export const InvoiceActions = new CInvoiceActions();

