import {createFeatureSelector, createSelector} from '@ngrx/store';
import {State} from '../../utils/abstract-reducers';
import {Entity} from '../../models/entity';
import {UserPrivateData} from '../../models/user-private-data';
import {UserPrivateDataReducers} from '../reducers/user-private-data.reducers';

export module UserPrivateDataSelectors {
  export const getState = createFeatureSelector<UserPrivateDataState>('userPrivateData');

  // Entity selectors
  export const {
    selectEntities,
    selectAll,
    selectTotal,
    selectIds,
  } = UserPrivateDataReducers.adapter.getSelectors(getState);

  const getEntityById = (id: string) => (state: UserPrivateDataState) => state.entities[id];
  export const getUserPrivateDataById = (id: string) => createSelector(getState, getEntityById(id));
}

export type UserPrivateDataState = State<Entity<UserPrivateData>>;
