// Angular
import {Component} from '@angular/core';
import {AuthenticationService} from '../../../../../core/services/authentication.service';

@Component({
  selector: 'kt-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {

  constructor(
    private authService: AuthenticationService
  ) {}

  logout() {
    this.authService.logout();
  }

}
