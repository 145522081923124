import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'serverStatus'
})
export class ServerStatusPipe implements PipeTransform {

  transform(value: number): string {
    const statuses = [{
      id: 1,
      name: 'open'
    }, {
      id: 2,
      name: 'suspended'
    }, {
      id: 3,
      name: 'cancelled'
    }, {
      id: 4,
      name: 'deleted'
    }];

    const status = statuses.find(_status => _status.id === value);

    if (status) {
      return status.name;
    }
    return '';
  }

}
