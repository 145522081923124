import {Injectable} from '@angular/core';
import {ResourceFormatter} from './resource-formatter.resource';
import {combineLatest, Observable, of, zip} from 'rxjs';
import {Action, AngularFirestore, AngularFirestoreDocument, DocumentChangeAction, DocumentSnapshot} from '@angular/fire/firestore';
import {User} from '../models/user';
import {User as FirebaseUser} from 'firebase';
import {UserRole} from '../models/user-role';
import {catchError, filter, map, mergeMap, switchMap, take, tap} from 'rxjs/operators';
import {AuthUser} from '../models/auth-user';
import {Entity} from '../models/entity';
import {Store} from '@ngrx/store';
import {AuthUserSelectors, AuthUserState} from '../store/selectors/auth-user.selectors';
import {CartSelectors, CartState} from '../store/selectors/cart.selectors';
import {UserPrivateData} from '../models/user-private-data';
import {CompanyService} from '../services/company.service';

@Injectable({
  providedIn: 'root'
})
export class UserResource {

  constructor(
    private formatter: ResourceFormatter,
    private firestore: AngularFirestore,
    private authUserStore: Store<AuthUserState>,
    private cartStore: Store<CartState>,
    private companyService: CompanyService
  ) {
  }

  public getUsers(): Observable<DocumentChangeAction<User>[]> {
    return this.authUserStore.select(AuthUserSelectors.selectAll).pipe(
      map(s => s ? s[0] : null),
      filter(v => !!v),
      take(1),
      switchMap((authUser: Entity<AuthUser>) => {

        const companyRef = this.firestore.doc(authUser.ref.parent.parent.path);

        return companyRef.collection<User>('users').stateChanges();
      })
    );
  }

  public getUserPrivateData(userId): Observable<Action<DocumentSnapshot<UserPrivateData>>> {
    const companyRef = this.companyService.getCurrentCompanyDocument();

    return companyRef.collection('users').doc(userId).collection('meta-data').doc<UserPrivateData>('privateData').snapshotChanges();
  }

  public getUserRoles(): Observable<DocumentChangeAction<UserRole>[]> {
    return this.firestore.collection<UserRole>('user-roles').stateChanges();
  }

  public getUser(user: FirebaseUser): Observable<Action<DocumentSnapshot<User>>> {
    return this.getUserRef<User>(user).snapshotChanges();
  }

  public getUserRole(userRoleId: string): Observable<Action<DocumentSnapshot<UserRole>>> {
    return this.firestore.collection('user-roles').doc<UserRole>(userRoleId).snapshotChanges();
  }

  public async setCompanyOnGuest(guestId: string, companyId: string) {
    const companyRef = this.firestore.collection('companies').doc(companyId);

    const user = {
      role: this.firestore.collection('user-roles').doc('guest').ref
    };

    try {
      return await companyRef.collection('guests').doc(guestId).set(user);
    } catch (e) {
      console.log({guestId, path: companyRef.ref.path, e});
    }

  }

  public getAuthUser(authUser: FirebaseUser): Observable<Action<Entity<AuthUser>>> {
    return this.getUser(authUser).pipe(
      switchMap(user => {
        if (!user) {
          return of([null, null]);
        }
        if (!user.payload.exists) {
          return of([null, null]);
        }
        const userRole = user.payload.data().role;
        console.log(userRole, user);
        return combineLatest([of(user), this.getUserRole(userRole.id)]);
      }),
      map(([user, userRole]) => {
        if (!user || !userRole) {
          return null;
        }
        console.log('test1');
        return {
          type: user.type,
          payload: {
            data: {
              role: userRole.payload.data(),
              user: user.payload.data(),
              isGuest: authUser.isAnonymous,
            },
            id: user.payload.id,
            ref: user.payload.ref
          }
        };
      }),
      switchMap(user => {
        const oldUser = this.authUserStore.select(AuthUserSelectors.selectAll).pipe(
          map((s: Entity<AuthUser>[]) => {
            if (!s) {
              return null;
            }
            console.log('test2');

            for (const v of s) {
              if (v.id === user.payload.id) {
                return v;
              }
            }

            return null;
          }),
          take(1)
        );
        if (!user) {
          return zip(of(null), oldUser);
        }
        return zip(of(user), oldUser);
      }),
      map(([user, oldUser]: [Action<Entity<AuthUser>> | null, Entity<AuthUser> | null]) => {
        if (!oldUser && user) {
          user.type = 'added';
        } else if (user && oldUser) {
          user.type = 'modified';
        }
        console.log('test3');

        if (!user && oldUser) {
          return {
            payload: oldUser,
            type: 'removed'
          };
        }
        return user;
      }),
      catchError((err, caught) => {
        console.log(err);
        return caught;
      }),
    );
  }

  public transferGuestCartToUser(userId: string) {
    const batch = this.firestore.firestore.batch();
    this.cartStore.select(CartSelectors.selectAll).pipe(
      take(1),
      mergeMap(cart => {
        const companyRef = this.companyService.getCurrentCompanyDocument();

        cart.forEach(product => {
          console.log({path: companyRef.ref.path});
          if (userId && product.id) {
            const ref = companyRef.collection('users').doc(userId).collection('cart').doc(product.id).ref;
            batch.set(ref, product.data);
          }
        });
        return companyRef.collection('users').doc(userId).collection('cart').get();
      }),
      map(oldCart => {
        return oldCart.docs.forEach(product => {
          batch.delete(product.ref);
        });
      }),
      tap(() => {
        batch.commit();
      }),
      catchError((err, caught) => {
        console.log(err);
        return caught;
      })
    ).subscribe();
  }

  private getUserRef<T = any>(user: FirebaseUser): AngularFirestoreDocument<T> {
    const companyRef = this.companyService.getCurrentCompanyDocument();

    let userRef: AngularFirestoreDocument<T>;
    if (user.isAnonymous) {
      userRef = companyRef.collection('guests').doc(user.uid);
    } else {
      userRef = this.firestore.doc(user.uid);
    }

    return userRef;
  }

}
