import {Injectable} from '@angular/core';
import {ResourceFormatter} from './resource-formatter.resource';
import {Observable} from 'rxjs';
import {ProductType} from '../models/product-type';
import {ProductTypeSetting} from '../models/product-type-setting';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ProductTypeResource {

  constructor(
    private formatter: ResourceFormatter,
    private firestore: AngularFirestore
  ) { }

  public getProductTypes(): Observable<DocumentChangeAction<ProductType>[]> {
    return this.firestore.collection<ProductType>('/product_types').stateChanges();
  }

  public getProductTypeSettings(productTypeId: string): Observable<ProductTypeSetting[]> {
    return this.formatter.collection('/product_types/' + productTypeId + '/options');
  }

}
