import {Actions, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {map, mergeMap, switchMap, takeUntil} from 'rxjs/operators';
import {Entity} from '../models/entity';
import {AbstractAction} from './abstract-action';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {StoreService} from '../services/store.service';

export abstract class AbstractEffects<T> {

  public get _query$(): Observable<Action> {
    return this.actions$.pipe(
      ofType(this.actions.QUERY),
      switchMap(action => {
        return this.getResources();
      }),
      mergeMap(actions => actions),
      map(action => {
        return {
          type: `[${this.prefix}] ${action.type}`,
          payload: Entity.fromSnapshot<T>(action.payload.doc)
        };
      }),
      takeUntil(this.store.onComplete(this.actions.QUERY))
    );
  }

  public constructor(
    protected actions$: Actions,
    protected store: StoreService
  ) {}

  public abstract get query$(): Observable<Action>;

  public abstract getResources(): Observable<DocumentChangeAction<T>[]>;

  public abstract get prefix(): string;

  protected abstract get actions(): AbstractAction<T>;

}
