import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {CKEditor5} from '@ckeditor/ckeditor5-angular';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-ckeditor-classic',
  templateUrl: './ckeditor-classic.component.html',
  styleUrls: ['./ckeditor-classic.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CkeditorClassicComponent)
    }
  ]
})
export class CkeditorClassicComponent implements OnInit, ControlValueAccessor {

  public Editor: CKEditor5.EditorConstructor = ClassicEditor.default;

  private _data: string;

  @Input() config: CKEditor5.Config = {
    toolbar: [
      'heading',
      '|',
      'bold',
      'italic',
      'alignment',
      'link',
      'bulletedList',
      'numberedList',
      'blockQuote',
      'insertTable',
      'undo',
      'redo'
    ],
    placeholder: 'Type the content here!'
  };

  @Input()
  set data(value) {
    this._data = value ? value : '';
  }

  get data() {
    return this._data;
  }

  @Output() dataChange = new EventEmitter();

  private onChange: (v: any) => void;
  private onTouch: () => void;

  constructor() { }

  ngOnInit() {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {
    this.data = obj;
  }

  onDataChange(data) {
    if (this.onChange) {
      this.onChange(data);
    }
    this.dataChange.emit(data);
  }

}
