import {State} from '../../utils/abstract-reducers';
import {AbstractAction, Added} from '../../utils/abstract-action';
import {Entity} from '../../models/entity';
import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {FrontPage} from '../../models/front-page';
import {FrontPageActions} from '../actions/front-page.actions';

class CFrontPageReducers {

  public readonly _adapter;

  private readonly _initialState: State<FrontPage>;

  constructor(
    public actions: AbstractAction<FrontPage>
  ) {
    this._adapter = createEntityAdapter<Entity<FrontPage>>({
      selectId: this.selectCompanyId
    });
    this._initialState = this._adapter.getInitialState();
  }

  selectCompanyId(frontPage: any): string {
    return frontPage.ref.parent.parent.id;
  }

  public reducers = (state?: State<FrontPage>, action?: Added<Entity<FrontPage>>) => {
    if (!state) {
      state = this.initialState;
    }

    if (!action) {
      return state;
    }

    if (action.type === this.actions.VALUE) {
      if ((state.ids as any).find(id => id === this.selectCompanyId(action.payload))) {
        return this._adapter.updateOne({
          id: this.selectCompanyId(action.payload),
          changes: action.payload
        }, state);
      }
      return this._adapter.addOne(action.payload, state);
    } else {
      return state;
    }
  }

  get adapter() {
    return this._adapter;
  }

  get initialState(): State<FrontPage> {
    return this._initialState;
  }

}

export interface State<T> extends EntityState<T> {
}

export const FrontPageReducers = new CFrontPageReducers(FrontPageActions);
