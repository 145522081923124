import {createFeatureSelector, createSelector} from '@ngrx/store';
import {State} from '../../utils/abstract-reducers';
import {Entity} from '../../models/entity';
import {AuthUserReducers} from '../reducers/auth-user.reducers';
import {User} from '../../models/user';
import {UserRole} from '../../models/user-role';

export module UserRoleSelectors {
  export const getState = createFeatureSelector<UserRoleState>('userRole');

  // Entity selectors
  export const {
    selectEntities,
    selectAll,
    selectTotal,
    selectIds,
  } = AuthUserReducers.adapter.getSelectors(getState);

  const getEntityById = (id: string) => (state: UserRoleState) => state.entities[id];
  export const getUserRoleById = (id: string) => createSelector(getState, getEntityById(id));
}

export type UserRoleState = State<Entity<UserRole>>;
