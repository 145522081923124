import {Action} from '@ngrx/store';
import {Entity} from '../models/entity';

export abstract class AbstractAction<T> {

  abstract get prefix(): string;

  public get QUERY(): string {
    return '[' + this.prefix + '] query';
  }

  public get ADDED(): string {
    return '[' + this.prefix + '] added';
  }

  public get MODIFIED(): string {
    return '[' + this.prefix + '] modified';
  }

  public get REMOVED(): string {
    return '[' + this.prefix + '] removed';
  }

  public get REMOVE(): string {
    return '[' + this.prefix + '] remove';
  }

  public get UPDATE(): string {
    return '[' + this.prefix + '] update';
  }

  public get SUCCESS(): string {
    return '[' + this.prefix + '] success';
  }

  public get VALUE(): string {
    return '[' + this.prefix + '] value';
  }

  public Query(): Query {
    return new Query(this.QUERY);
  }

  public Added(payload: Entity<T>): Added<Entity<T>> {
    return new Added(payload, this.ADDED);
  }

  public Modified(payload: Entity<T>): Modified<Entity<T>> {
    return new Modified(payload, this.MODIFIED);
  }

  public Removed(payload: Entity<T>): Removed<Entity<T>> {
    return new Removed<Entity<T>>(payload, this.REMOVED);
  }

  public Remove(id: string): Remove<T> {
    return new Remove(id, this.REMOVE);
  }

  public Update(id: string, changes: Partial<T>): Update<T> {
    return new Update(id, changes, this.UPDATE);
  }

  public Success(): Success {
    return new Success(this.SUCCESS);
  }

  public Value(payload: Entity<T>): Modified<Entity<T>> {
    return new Value(payload, this.MODIFIED);
  }

}

export class Query implements Action {
  constructor(public readonly type) {}
}

export class Added<T> implements Action {
  constructor(public payload: T, public readonly type: string) {}
}

export class Modified<T> implements Action {
  constructor(public payload: T, public readonly type: string) {
  }
}

export class Removed<T> implements Action {
  constructor(public payload: T, public readonly type: string) {
  }
}

export class Remove<T> implements Action {
  constructor(public id: string, public readonly type: string) {
  }
}

export class Update<T> implements Action {
  constructor(
    public id: string,
    public changes: Partial<T>,
    public readonly type: string
  ) {
  }
}

export class Success implements Action {
  constructor(public readonly type: string) {}
}

export class Value<T> implements Action {
  constructor(public payload: T, public readonly type: string) {
  }
}

export type Actions<T> = Query | Added<Entity<T>> | Modified<Entity<T>> | Removed<Entity<T>> | Update<T> | Success;

