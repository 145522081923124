import {Injectable} from '@angular/core';
import {CompanyResource} from '../resources/company.resource';
import {catchError, mergeMap, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {Entity} from '../models/entity';
import {Company} from '../models/company';
import {from, Observable, of} from 'rxjs';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private companyId: string;

  private $company: Observable<Entity<Company>>;
  private company: Entity<Company>;

  constructor(
    private companyResource: CompanyResource,
    private firestore: AngularFirestore
    ) {}

  async getCompanyIdByDomainName(): Promise<string> {
    if (this.companyId) {
      return this.companyId;
    }
    const domain = window.location.hostname;
    const company = await this.companyResource.getCompanyByDomain(domain).pipe(take(1)).toPromise();
    if (!company) {
      return null;
    } else {
      this.companyId = company.id;
      return this.companyId;
    }
  }

  public getCurrentCompanyAsync(): Observable<Entity<Company>> {
    if (!this.$company) {
      this.$company = from(this.getCompanyIdByDomainName()).pipe(
        switchMap(companyId => {
          if (companyId) {
            return this.companyResource.getCompanyById(companyId);
          }
          throw new Error('No company found');
        }),
        tap(company => {
          this.company = company;
        }),
        shareReplay(1)
      );
    }
    return this.$company;
  }

  public getCurrentCompany(): Entity<Company> {
    return this.company;
  }

  public getCurrentCompanyDocument(): AngularFirestoreDocument {
    return this.firestore.doc(this.getCurrentCompany().ref.path);
  }

  public saveCompanyStyles(styles: string): Observable<void> {
    return from(this.getCompanyIdByDomainName()).pipe(
      mergeMap(companyId => {
        return this.companyResource.saveCompany(companyId, {style: styles});
      })
    );
  }
}
