import {DocumentReference, DocumentSnapshot, QueryDocumentSnapshot} from '@angular/fire/firestore';
import * as firebase from 'firebase';
import * as _ from 'lodash';
import {Date} from './date';

export class Entity<T> {
  data: T;
  ref: DocumentReference;
  id: string;

  static fromSnapshot<T>(doc: DocumentSnapshot<T> | QueryDocumentSnapshot<T>) {
    const data = this.formatDataForNgrx(doc.data());

    const entity = new Entity<T>();
    entity.ref = doc.ref;
    entity.data = data;
    entity.id = entity.ref.id;

    return entity;
  }

  static formatDataForNgrx(data: any) {

    if (!data || !(data instanceof Object)) {
      return data;
    }

    data = _.mapValues(data, (value) => {
      if (value instanceof firebase.firestore.Timestamp) {
        const date: Date = {
          ISOString: value.toDate().toISOString(),
        };
        return date;
      }
      if (value instanceof firebase.firestore.DocumentReference) {
        return value;
      }
      if (value instanceof Object) {
        return this.formatDataForNgrx(value);
      }
      return value;
    });

    return data;
  }
}
