import {Component, OnInit} from '@angular/core';
import {CompanyService} from './core/services/company.service';
import {environment} from '../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {from, of, zip} from 'rxjs';
import {catchError, take} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  isLoading = true;
  companyFound = true;

  public constructor(
    private companyService: CompanyService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.translate.setDefaultLang('en');
    this.translate.use('en');

    zip(
      this.companyService.getCurrentCompanyAsync(),
      this.importStyles(),
      ).pipe(
        take(1),
        catchError(err => {
          this.companyFound = false;
          return of(err);
        })
      ).subscribe(() => {
        this.isLoading = false;
      });
  }

  async importStyles() {
    const companyId = await this.companyService.getCompanyIdByDomainName();

    if (!companyId) {
      return;
    }

    const link = document.createElement( 'link' );
    link.href = environment.backend.url + '/company/' + companyId + '/styles.css';
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.media = 'screen,print';

    document.getElementsByTagName( 'head' )[0].appendChild( link );
  }



}
