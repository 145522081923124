import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument, DocumentChangeAction} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {CartProduct} from '../models/cart-product';
import {User} from 'firebase';
import {CompanyService} from '../services/company.service';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CartResource {

  constructor(
    private firestore: AngularFirestore,
    private companyService: CompanyService
  ) { }

  getCart(user: User): Observable<DocumentChangeAction<CartProduct>[]> {
    return this.getUserRef(user).collection<CartProduct>('cart').stateChanges().pipe(catchError((err, caught) => {
      console.log(err);
      return caught;
    }));
  }

  async editCartProduct(productId: string, user: User, changes: Partial<CartProduct>|CartProduct): Promise<void> {
    await this.getUserRef(user).collection('cart').doc(productId).set(changes);
  }

  async removeCartProduct(productId: string, user: User): Promise<void> {
    await this.getUserRef(user).collection('cart').doc(productId).delete();
  }

  private getUserRef<T = any>(user: User): AngularFirestoreDocument<T> {
    const companyRef = this.companyService.getCurrentCompanyDocument();

    let userRef: AngularFirestoreDocument<T>;
    if (user.isAnonymous) {
      userRef = companyRef.collection('guests').doc(user.uid);
    } else {
      userRef = this.firestore.doc(user.uid);
    }
    return userRef;
  }

}
