import {AbstractAction} from '../../utils/abstract-action';
import {AuthUser} from '../../models/auth-user';

class CAuthUserActions extends AbstractAction<AuthUser> {

  get prefix(): string {
    return 'AuthUser';
  }
}


export const AuthUserActions = new CAuthUserActions();

