import {AbstractAction} from '../../utils/abstract-action';
import {FrontPage} from '../../models/front-page';

class CFrontPageActions extends AbstractAction<FrontPage> {
  get prefix(): string {
    return 'FrontPage';
  }
}

export const FrontPageActions = new CFrontPageActions();
