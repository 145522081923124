import {IActions} from './i-actions';
import {IReducers} from './i-reducers';
import {AbstractAction} from './abstract-action';
import {AbstractReducers} from './abstract-reducers';
import {SampleActions} from '../store/actions/sample.actions';
import {SampleReducers} from '../store/reducers/sample.reducers';
import {IEffects} from './i-effects';
import {Type} from '@angular/core';
import {AbstractEffects} from './abstract-effects';

export abstract class AbstractStore<T> implements IActions<T>, IReducers<T>, IEffects<T> {


  abstract prefix(): string;

  abstract effects(): Type<AbstractEffects<T>>;

  reducers(): AbstractReducers<T> {
    return new SampleReducers(this.actions());
  }

  actions(): AbstractAction<T> {
    return new SampleActions(this.prefix());
  }

}
