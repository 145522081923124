import {createFeatureSelector, createSelector} from '@ngrx/store';
import {State} from '../../utils/abstract-reducers';
import {Entity} from '../../models/entity';
import {ProductTypeReducers} from '../reducers/product-type.reducers';
import {ProductType} from '../../models/product-type';

export module ProductTypeSelectors {
  export const getState = createFeatureSelector<ProductTypeState>('productTypes');

  // Entity selectors
  export const {
    selectEntities,
    selectAll,
    selectTotal,
    selectIds,
  } = ProductTypeReducers.adapter.getSelectors(getState);

  const getEntityById = (id: string) => (state: ProductTypeState) => state.entities[id];
  export const getProductTypeById = (id: string) => createSelector(getState, getEntityById(id));
}

export type ProductTypeState = State<Entity<ProductType>>;
