import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import {Observable, Subscription} from 'rxjs';
import {Invoice} from '../../../../core/models/invoice';
import {Entity} from '../../../../core/models/entity';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['id', 'paid', 'paymentGateway', 'date'];
  dataSource: MatTableDataSource<any>;
  subscription: Subscription;

  @Input()
  private invoices$: Observable<Entity<Invoice>[]>;

  ngOnInit(): void {
    this.subscription = this.invoices$.pipe(
      tap(invoices => {
        const mapped = invoices.map((invoice) => {
          return {id: invoice.id, ...invoice.data};
        });
        this.dataSource = new MatTableDataSource(mapped);
      })).subscribe();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}
