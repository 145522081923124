import {Actions} from './abstract-action';
import {Store} from '@ngrx/store';
import {StoreService} from '../services/store.service';

export abstract class AbstractService<S> {

  protected constructor(
    private _store: Store<S>,
    private storeSer: StoreService
  ) { }

  protected dispatch<T, K = any>(action: Actions<T>, _store?: Store<K>) {
    let store = _store;
    if (!store) {
      store = this._store as any;
    }

    this.storeSer.dispatch(action, store);
  }
}
