import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {StripeEvents} from '../types/types';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from './authentication.service';
import {take} from 'rxjs/operators';
import {CompanyService} from './company.service';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  private handler;

  private _$events: BehaviorSubject<StripeEvents> = new BehaviorSubject(undefined);

  private paymentAction: (token: string) => void | Promise<void> = null;

  constructor(
    private http: HttpClient,
    private authentication: AuthenticationService,
    private companyService: CompanyService
  ) {
    this.handler = StripeCheckout.configure({
      key: environment.stripe.key,
      locale: 'auto',
      currency: 'EUR',
      token: async (token) => {
        this._$events.next('charging');
        await this.chargePayment(token.id);
      }
    });
  }

  async openPaymentModal(amount: number, action: (token: string) => void | Promise<void>) {
    const company = await this.companyService.getCurrentCompanyAsync().pipe(take(1)).toPromise();
    this.handler.open({
      name: company.data.name,
      amount: parseInt(amount.toFixed(2).replace('.', ''), 10),
    });
    this.paymentAction = action;
  }

  closePaymentModal() {
    this.handler.close();
    this.paymentAction = null;
  }

  async createInvoiceFromCart() {
    const idToken = await this.authentication.getCurrentAuth().user.getIdToken();
    const company = this.companyService.getCurrentCompany();

    const {invoiceId} = await (this.http.post<{invoiceId: string}>(environment.backend.url + '/payment/cart', {
      companyId: company.ref.id
    }, {
      headers: {
        idToken
      }
    })).pipe(take(1)).toPromise();

    return invoiceId;
  }

  async chargePayment(token) {
    if (this.paymentAction) {
      await Promise.resolve(this.paymentAction(token));
    }

    this.paymentAction = null;

    this._$events.next('charged');
  }

  async invoicePayment(invoiceId: string, stripeToken: string) {
    const company = this.companyService.getCurrentCompany();

    await (this.http.post<{invoiceId: string}>(environment.backend.url + '/payment/create_payment', {
      companyId: company.ref.id,
      invoiceId,
      stripeToken
    })).pipe(take(1)).toPromise();
  }

  get events(): Observable<StripeEvents> {
    return this._$events.asObservable();
  }
}
