import {createFeatureSelector, createSelector} from '@ngrx/store';
import {State} from '../../utils/abstract-reducers';
import {Entity} from '../../models/entity';
import {Invoice} from '../../models/invoice';
import {InvoiceReducers} from '../reducers/invoice.reducers';

export module InvoiceSelectors {
  export const getState = createFeatureSelector<InvoiceState>('invoice');

  // Entity selectors
  export const {
    selectEntities,
    selectAll,
    selectTotal,
    selectIds,
  } = InvoiceReducers.adapter.getSelectors(getState);

  const getEntityById = (id: string) => (state: InvoiceState) => state.entities[id];
  export const getInvoiceByInvoiceId = (id: string) => createSelector(getState, getEntityById(id));


  const getEntitiesByUserId = (userId: string) => (state: InvoiceState) => {
    let keys = Object.keys(state.entities);
    keys = keys.filter((key) => {
      return state.entities[key].data.user.id === userId;
    });
    return keys.map(key => state.entities[key]);
  };
  export const getInvoiceByUserId = (userId: string) => createSelector(getState, getEntitiesByUserId(userId));


  const getEntitiesByCompanyId = (companyId: string) => (state: InvoiceState) => {
    const keys = Object.keys(state.entities);
    return keys.map(key => state.entities[key]);
  };
  export const getInvoiceByCompanyId = (companyId: string) => createSelector(getState, getEntitiesByCompanyId(companyId));
}

export type InvoiceState = State<Entity<Invoice>>;
