import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Entity} from '../models/entity';
import {AbstractAction, Actions, Added, Modified, Removed} from './abstract-action';

export class AbstractReducers<T> {

  public readonly _adapter;

  private readonly _initialState: State<T>;

  constructor(
    public actions: AbstractAction<T>
  ) {
    this._adapter = createEntityAdapter<Entity<T>>();
    this._initialState = this._adapter.getInitialState();
  }

  public reducers = (state?: State<T>, action?: Actions<T>) => {
    if (!state) {
      state = this.initialState;
    }

    if (!action) {
      return state;
    }

    switch (action.type) {

      case this.actions.VALUE:
        if (state.entities[(action as Added<Entity<T>>).payload.id]) {
          return this._adapter.updateOne({
            id: (action as Modified<Entity<T>>).payload.ref.id,
            changes: (action as Modified<Entity<T>>).payload
          }, state);
        } else {
          return this._adapter.addOne((action as Added<Entity<T>>).payload, state);
        }

      case this.actions.ADDED:
        return this._adapter.addOne((action as Added<Entity<T>>).payload, state);

      case this.actions.MODIFIED:
        return this._adapter.updateOne({
          id: (action as Modified<Entity<T>>).payload.ref.id,
          changes: (action as Modified<Entity<T>>).payload
        }, state);

      case this.actions.REMOVED:
        return this._adapter.removeOne((action as Removed<Entity<T>>).payload.ref.id, state);

      default:
        return state;
    }
  }


  get adapter() {
    return this._adapter;
  }

  get initialState(): State<T> {
    return this._initialState;
  }
}

export interface State<T> extends EntityState<T> {
}
