import {Product} from '../../models/product';
import {AbstractAction} from '../../utils/abstract-action';

class CProductActions extends AbstractAction<Product> {
  get prefix(): string {
    return 'Product';
  }
}

export const ProductActions = new CProductActions();

