import { Injectable } from '@angular/core';
import {ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarTitleService {

  title: ReplaySubject<string>;

  constructor() {
    this.title = new ReplaySubject<string>();
  }

  getTitle(): ReplaySubject<string> {
    return this.title;
  }

  setTitle(title) {
    this.title.next(title);
  }
}
