import {
  Directive, HostBinding,
  Input,
} from '@angular/core';
import {ColorPalette} from '../models/color-palette';

@Directive({
  selector: '[color]',
})
export class ColorDirective {

  @HostBinding('class.color-danger')
  get colorDanger() {
    return this._color === 'danger';
  }

  @HostBinding('class.color-white')
  get colorWhite() {
    return this._color === 'white';
  }

  @HostBinding('class.color-dark')
  get colorDark() {
    return this._color === 'dark';
  }

  @HostBinding('class.color-default')
  get colorDefault() {
    return this._color === 'default';
  }


  @HostBinding('class.color-primary')
  get colorPrimary() {
    return this._color === 'primary';
  }


  @HostBinding('class.color-accent')
  get colorAccent() {
    return this._color === 'accent';
  }


  @HostBinding('class.color-warn')
  get colorWarn() {
    return this._color === 'warn';
  }

  private _color: ColorPalette;

  constructor(
  ) {
  }

  @Input()
  set color(value: ColorPalette) {
    this._color = value;
  }

}
