import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentChangeAction} from '@angular/fire/firestore';
import {Product} from '../models/product';
import {Observable} from 'rxjs';
import {CompanyService} from '../services/company.service';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductResource {

  constructor(
    private firestore: AngularFirestore,
    private companyService: CompanyService
  ) { }

  getProducts(): Observable<DocumentChangeAction<Product>[]> {
    const companyRef = this.companyService.getCurrentCompanyDocument();
    return companyRef.collection<Product>('products').stateChanges().pipe(catchError((err, caught) => {
      console.log(err);
      return caught;
    }));
  }

  async editProduct(id: string, changes: Partial<Product>|Product): Promise<void> {
    const companyRef = this.companyService.getCurrentCompanyDocument();

    if (id) {
      await companyRef.collection('products').doc(id).update(changes);
    } else {
      await companyRef.collection('products').add(changes);
    }
  }


}
