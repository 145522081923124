import {Injectable} from '@angular/core';
import {Observable, zip} from 'rxjs';
import {Store} from '@ngrx/store';
import {AbstractService} from '../utils/abstract-service';
import {Entity} from '../models/entity';
import {User} from '../models/user';
import {UserSelectors, UserState} from '../store/selectors/user.selectors';
import {UserStore} from '../store/stores/user-store';
import {UserRole} from '../models/user-role';
import {UserRoleSelectors, UserRoleState} from '../store/selectors/user-role.selectors';
import {UserRoleStore} from '../store/stores/user-role-store';
import {UserResource} from '../resources/user.resource';
import {CompanyService} from './company.service';
import {StoreService} from './store.service';
import {UserPrivateDataSelectors, UserPrivateDataState} from '../store/selectors/user-private-data.selectors';
import {UserPrivateDataActions} from '../store/actions/user-private-data.actions';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService extends AbstractService<UserState> {

  constructor(
    private userStore: Store<UserState>,
    private userPrivateDataStore: Store<UserPrivateDataState>,
    private userRoleStore: Store<UserRoleState>,
    private userResource: UserResource,
    private companyService: CompanyService,
    private storeService: StoreService
  ) {
    super(userStore, storeService);
  }

  getUsers(): Observable<Entity<User>[]> {
    this.dispatch(UserStore.actions().Query());
    return this.userStore.select(UserSelectors.selectAll);
  }

  getUser(userId: string): Observable<Entity<User>> {
    this.dispatch(UserStore.actions().Query());
    return this.userStore.select(UserSelectors.getUserById(userId));
  }

  getUserWithPrivateData(userId: string): Observable<Entity<User>> {
    this.dispatch(UserStore.actions().Query());
    this.dispatch(UserPrivateDataActions.Query(userId));
    return zip(
      this.userStore.select(UserSelectors.getUserById(userId)),
      this.userPrivateDataStore.select(UserPrivateDataSelectors.getUserPrivateDataById(userId))
    ).pipe(map((userData) => {
      const user = userData[0];
      if (user) {
        user.data.metaData = {privateData: userData[1].data};
      }
      return user;
    }));
  }

  getUserRoles(): Observable<Entity<UserRole>[]> {
    this.dispatch(UserRoleStore.actions().Query(), this.userRoleStore);
    return this.userStore.select(UserRoleSelectors.selectAll);
  }

  getUserRole(roleId: string): Observable<Entity<UserRole>> {
    this.dispatch(UserRoleStore.actions().Query(), this.userRoleStore);
    return this.userRoleStore.select(UserRoleSelectors.getUserRoleById(roleId));
  }

  async setCompanyOnGuest(guestId: string) {
    const companyId = await this.companyService.getCompanyIdByDomainName();
    return this.userResource.setCompanyOnGuest(guestId, companyId);
  }

  transferGuestCartToUser(userId: string) {
    return this.userResource.transferGuestCartToUser(userId);
  }

}
