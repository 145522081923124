import {Action} from '@ngrx/store';
import {CartReducers} from '../reducers/cart.reducers';
import {CartProduct} from '../../models/cart-product';
import {User} from 'firebase';

export module CartActions {

  export const QUERY = '[Cart] query servers';

  export const ADDED = '[Cart] added';
  export const MODIFIED = '[Cart] modified';
  export const REMOVED = '[Cart] removed';

  export const REMOVE = '[Cart] remove';
  export const UPDATE = '[Cart] update';
  export const SUCCESS = '[Cart] success';


// Initial query
  export class Query implements Action {
    readonly type = QUERY;

    constructor(public user: User) {
    }
  }

// AngularFire2 StateChanges
  export class Added implements Action {
    readonly type = ADDED;

    constructor(public payload: CartReducers.EntityType) {
    }
  }

  export class Modified implements Action {
    readonly type = MODIFIED;

    constructor(public payload: CartReducers.EntityType) {
    }
  }

  export class Removed implements Action {
    readonly type = REMOVED;

    constructor(public payload: CartReducers.EntityType) {
    }
  }

  export class Remove implements Action {
    readonly type = REMOVE;

    constructor(
      public productId: string,
      public user: User
    ) {
    }
  }


// Run a Firestore Update
  export class Update implements Action {
    readonly type = UPDATE;

    constructor(
      public productId: string,
      public user: User,
      public changes: Partial<CartProduct>|CartProduct,
    ) {
    }
  }

  export class Success implements Action {
    readonly type = SUCCESS;

    constructor() {
    }
  }

  export type Actions = Query | Added | Modified | Removed | Update | Success;
}

