import {ActionReducerMap} from '@ngrx/store';
import {routerReducer} from '@ngrx/router-store';
import {ServerReducers} from './server.reducers';
import {ServerVersionReducers} from './server-version.reducers';
import {ServerStatusReducers} from './server-status.reducers';
import {ProductReducers} from './product.reducers';
import {ProductTypeReducers} from './product-type.reducers';
import {AuthUserReducers} from './auth-user.reducers';
import {CartReducers} from './cart.reducers';
import {UserStore} from '../stores/user-store';
import {UserRoleStore} from '../stores/user-role-store';
import {InvoiceReducers} from './invoice.reducers';
import {UserPrivateDataReducers} from './user-private-data.reducers';
import {FrontPageReducers} from './front-page.reducers';

export const appReducers: ActionReducerMap<any> = {
  router: routerReducer,
  servers: ServerReducers.reducers,
  serverStatus: ServerStatusReducers.reducers,
  serverVersions: ServerVersionReducers.reducers,
  products: ProductReducers.reducers,
  invoice: InvoiceReducers.reducers,
  cart: CartReducers.reducers,
  frontPage: FrontPageReducers.reducers,
  productTypes: ProductTypeReducers.reducers,
  authUser: AuthUserReducers.reducers,
  user: UserStore.reducers().reducers,
  userRole: UserRoleStore.reducers().reducers,
  userPrivateData: UserPrivateDataReducers.reducers,
};
