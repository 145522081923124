import {MenuDefinition} from './core/interfaces/menu-definition.interface';

const _ = (string: string) => {
  return string;
};

export const menu: MenuDefinition = {
  'dashboard': {
    label: _('Dashboard'),
    route: '/dashboard',
    backBtn: false,
    items: {
      'servers': {
        label: _('Servers'),
        route: '/dashboard/servers',
        backBtn: false,
        items: {
          'manage': {
            label: 'Manage',
            route: '/dashboard/servers/manage',
            icon: 'la-list'
          },
          'renew': {
            label: 'Renew',
            route: '/dashboard/servers/renew',
            icon: 'la-refresh'
          },
          ':id': {
            label: _('Server'),
            route: '/dashboard/servers/:id',
            hidden: true,
            items: {
              'console': {
                label: _('Console'),
                route: '/dashboard/servers/:id/console',
                icon: 'flaticon2-ui'
              },
              'ftp': {
                label: _('Sftp'),
                route: '/dashboard/servers/:id/ftp',
                icon: 'la-folder-open'
              },
              'version': {
                label: _('Versions'),
                route: '/dashboard/servers/:id/version',
                icon: 'la-list'
              }
            }
          }
        }
      },
      'products': {
        label: _('Products'),
        route: '/dashboard/products',
        backBtn: false,
        permission: 'product.list',
        items: {
          'add': {
            icon: 'la-plus',
            label: _('Add Product'),
            route: '/dashboard/products/add',
            permission: 'product.update',
          },
          ':id': {
            label: _('Product'),
            route: '/dashboard/products/:id',
            hidden: true,
            permission: 'product.list',
          }
        }
      },
      'profile': {
        label: _('Profile'),
        route: '/dashboard/profile',
        backBtn: false,
        items: {
          'invoices': {
            icon: 'la-credit-card',
            label: _('Invoices'),
            route: '/dashboard/profile/invoices',
            items: {
              ':invoiceId': {
                hidden: true,
                icon: 'la-credit-card',
                label: _('Invoices'),
                route: '/dashboard/profile/invoices/:invoiceId',
              }
            }
          }
        }
      },
      'company': {
        label: _('Company'),
        route: '/dashboard/company',
        backBtn: false,
        items: {
          'users': {
            icon: 'la-users',
            label: _('Users'),
            route: '/dashboard/company/users',
            permission: 'company.users'
          },
          'invoices': {
            icon: 'la-credit-card',
            label: _('Invoices'),
            route: '/dashboard/company/invoices',
            permission: 'invoice.company',
            items: {
              ':invoiceId': {
                hidden: true,
                icon: 'la-credit-card',
                label: _('Invoices'),
                route: '/dashboard/profile/invoices/:invoiceId',
              }
            }
          },
          'theme': {
            icon: 'la-pencil',
            label: _('Theme'),
            route: '/dashboard/company/theme',
            permission: 'company.theme'
          }
        }
      },
      'shop': {
        label: _('Shop'),
        route: '/dashboard/shop',
        backBtn: false,
        items: {
          'image': {
            icon: 'la-image',
            label: _('Shop image'),
            route: '/dashboard/shop/image',
            permission: 'company.shop'
          }
        }
      }
    }
  }
};
