import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Server} from '../../models/server.model';
import {ServerActions} from '../actions/server.actions';
import {Entity} from '../../models/entity';

export module ServerReducers {
  export type EntityType = Entity<Server>;

  // Entity adapter
  export const adapter = createEntityAdapter<EntityType>();

  export interface State extends EntityState<EntityType> { }


  export const initialState: State = adapter.getInitialState();

// Reducer

  export function reducers(
    state: State = initialState,
    action: ServerActions.Actions) {

    switch (action.type) {

      case ServerActions.ADDED:
        return adapter.addOne(action.payload, state);

      case ServerActions.MODIFIED:
        return adapter.updateOne({
          id: action.payload.id,
          changes: action.payload
        }, state);

      case ServerActions.REMOVED:
        return adapter.removeOne(action.payload.id, state);

      default:
        return state;
    }
  }
}
