import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {AbstractAction} from '../../utils/abstract-action';
import {AbstractEffects} from '../../utils/abstract-effects';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {StoreService} from '../../services/store.service';
import {map, switchMap, takeUntil} from 'rxjs/operators';
import {Entity} from '../../models/entity';
import {FrontPage} from '../../models/front-page';
import {ShopResource} from '../../resources/shop.resource';
import {FrontPageActions} from '../actions/front-page.actions';

@Injectable()
export class FrontPageEffects extends AbstractEffects<FrontPage> {


  constructor(
    protected actions$: Actions,
    private storeService: StoreService,
    private shopResource: ShopResource,
  ) {
    super(actions$, storeService);
  }

  getResources(): Observable<DocumentChangeAction<FrontPage>[]> {
    return;
  }

  get prefix(): string {
    return 'FrontPage';
  }

  @Effect()
  get query$(): Observable<Action> {
    return this.actions$.pipe(
      ofType(this.actions.QUERY),
      switchMap((action) => {
        return this.shopResource.getFrontPage();
      }),
      map(action => {
        return {
          type: `[${this.prefix}] ${action.type}`,
          payload: Entity.fromSnapshot<FrontPage>(action.payload)
        };
      }),
      takeUntil(this.store.onComplete(this.actions.QUERY))
    );
  }

  protected get actions(): AbstractAction<FrontPage> {
    return FrontPageActions;
  }
}
