import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SweetAlertComponent} from '../sweet-alert/sweet-alert.component';
import {SweetAlertWarningComponent} from '../sweet-alert-warning/sweet-alert-warning.component';
import {SweetAlertSuccessComponent} from '../sweet-alert-success/sweet-alert-success.component';

@Component({
  selector: 'app-sweet-alert-confirm',
  templateUrl: './sweet-alert-confirm.component.html',
  styleUrls: ['./sweet-alert-confirm.component.scss']
})
export class SweetAlertConfirmComponent extends SweetAlertComponent implements OnInit {

  @Input() successTitle = 'Successful';
  @Input() successBody: string;

  @Input() cancelTitle = 'Cancelled';
  @Input() cancelBody: string;

  @ViewChild('confirmAlertComponent', {static: false}) confirmAlertComponent: SweetAlertWarningComponent;
  @ViewChild('confirmAlert', {static: false}) confirmAlert: SweetAlertSuccessComponent;
  @ViewChild('cancelAlert', {static: false}) cancelAlert: SweetAlertWarningComponent;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  show() {
    this.confirmAlertComponent.show();
  }

  onConfirm() {
    this.confirmAlert.show();
    this.confirm.emit();
  }

  onCancel() {
    this.cancelAlert.show();
    this.cancel.emit();
  }

}
