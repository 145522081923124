import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, share, take, tap} from 'rxjs/operators';
import {
  Action,
  AngularFirestore,
  DocumentChangeAction,
  DocumentSnapshotDoesNotExist,
  DocumentSnapshotExists, QueryDocumentSnapshot,
  QueryFn
} from '@angular/fire/firestore';
import {DocumentFormat} from '../models/document-format.model';

@Injectable({
  providedIn: 'root'
})
export class ResourceFormatter {

  private observables: { [path: string]: Observable<any> } = {};

  constructor(
    private firestore: AngularFirestore
  ) {
  }

  public formatCollection(docs: DocumentChangeAction<any>[]): DocumentFormat[] {
    const changed = [];

    for (const action of docs) {
      const doc = action.payload.doc;
      changed.push({
        type: action.type,
        data: doc.data(),
        ref: {
          id: doc.id,
          path: doc.ref.path
        }
      });
    }
    return changed;
  }

  public formatDoc(action: Action<DocumentSnapshotExists<any> | DocumentSnapshotDoesNotExist>): DocumentFormat {
    const doc = action.payload;
    return {
      type: action.type,
      data: doc.data(),
      ref: {
        id: doc.id,
        path: doc.ref.path
      }
    };
  }

  public doc<T = DocumentFormat>(path: string): Observable<T> {
    if (this.observables[path] !== undefined) {
      return this.observables[path];
    } else {
      return this.firestore.doc(path).snapshotChanges().pipe(
        map(value => {
          return <any>this.formatDoc(value);
        }),
        share()
      );
    }
  }

  public collection(path: string, queryFn?: QueryFn) {
    if (queryFn === undefined && this.observables[path] !== undefined) {
      return this.observables[path];
    } else {
      return this.firestore.collection(path, queryFn).snapshotChanges().pipe(
        map(value => {
          return this.formatCollection(value);
        }),
        share()
      );
    }
  }

  public get<T>(obs: Observable<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      obs.pipe(
        take(1),
        catchError(err => {
          reject(err);
          return of(err);
        }),
        tap(data => {
          resolve(data as any);
        })
      ).subscribe();
    });
  }

}
