import {Injectable, NgZone} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../services/authentication.service';
import {filter, map} from 'rxjs/operators';

@Injectable()
export class PermGuard implements CanActivate {

  constructor(private router: Router,
              private ngZone: NgZone,
              private authService: AuthenticationService
              ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.getAuthUser().pipe(
      filter((auth) => !!auth),
      map((auth) => {
        const child = this.getLastChild(state.root);
        const config = child.routeConfig.data;

        if (!config) {
          return true;
        }

        const permission = config['permission'];

        if (!permission) {
          return true;
        }

        const navigate = this.router.createUrlTree(['/dashboard']);

        // Route has required permission and user does not have any permissions therefor the user is not allowed
        if (!auth.data.role.permissions) {
          return navigate;
        }

        return auth.data.role.permissions.indexOf(permission) >= 0;
      }),
    );
  }

  getLastChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {

    if (route.children.length > 0) {
      const child = route.children[0];
      if (child.data.redirected) {
        return route;
      } else {
        return this.getLastChild(child);
      }
    } else {
      return route;
    }
  }
}
