import {Action} from '@ngrx/store';
import {ServerVersion} from '../../models/server-version.model';
import {ServerVersionReducers} from '../reducers/server-version.reducers';

export module ServerVersionActions {

  export const QUERY = '[ServerVersion] query servers';

  export const ADDED = '[ServerVersion] added';
  export const MODIFIED = '[ServerVersion] modified';
  export const REMOVED = '[ServerVersion] removed';

  export const UPDATE = '[ServerVersion] update';
  export const SUCCESS = '[ServerVersion] update success';


// Initial query
  export class Query implements Action {
    readonly type = QUERY;

    constructor() {
    }
  }

// AngularFire2 StateChanges
  export class Added implements Action {
    readonly type = ADDED;

    constructor(public payload: ServerVersionReducers.EntityType) {
    }
  }

  export class Modified implements Action {
    readonly type = MODIFIED;

    constructor(public payload: ServerVersionReducers.EntityType) {
    }
  }

  export class Removed implements Action {
    readonly type = REMOVED;

    constructor(public payload: ServerVersionReducers.EntityType) {
    }
  }


// Run a Firestore Update
  export class Update implements Action {
    readonly type = UPDATE;

    constructor(
      public id: string,
      public changes: Partial<ServerVersion>,
    ) {
    }
  }

  export class Success implements Action {
    readonly type = SUCCESS;

    constructor() {
    }
  }

  export type Actions = Query | Added | Modified | Removed | Update | Success;
}

