import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {StoreService} from '../../services/store.service';
import {InvoiceActions, QueryCompanyId, QueryInvoiceId, QueryUserId} from '../actions/invoice.actions';
import {Invoice} from '../../models/invoice';
import {InvoiceResource} from '../../resources/invoice.resource';
import {map, mergeMap, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Entity} from '../../models/entity';

@Injectable()
export class InvoiceEffects {

  constructor(
    protected actions$: Actions,
    protected store: StoreService,
    private invoiceResource: InvoiceResource,
  ) {}

  get prefix(): string {
    return 'Invoice';
  }

  @Effect()
  queryUserId$: Observable<Action> = this.actions$.pipe(
    ofType(this.actions.QUERYUSERID),
    switchMap((action: QueryUserId) => {
      return this.invoiceResource.getInvoicesByUserId(action.userId);
    }),
    mergeMap(actions => actions),
    map(action => {
      return {
        type: `[${this.prefix}] ${action.type}`,
        payload: Entity.fromSnapshot<Invoice>(action.payload.doc)
      };
    }),
    takeUntil(this.store.onComplete(this.actions.QUERYUSERID))
  );

  @Effect()
  queryInvoiceId$: Observable<Action> = this.actions$.pipe(
    ofType(this.actions.QUERYINVOICEID),
    switchMap((action: QueryInvoiceId) => {
      return this.invoiceResource.getInvoiceById(action.invoiceId);
    }),
    map(action => {
      return {
        type: `[${this.prefix}] ${action.type}`,
        payload: Entity.fromSnapshot<Invoice>(action.payload)
      };
    }),
    takeUntil(this.store.onComplete(this.actions.QUERYINVOICEID))
  );

  @Effect()
  queryCompanyId$: Observable<Action> = this.actions$.pipe(
    ofType(this.actions.QUERYCOMPANYID),
    switchMap((action: QueryCompanyId) => {
      return this.invoiceResource.getInvoicesByCompanyId();
    }),
    mergeMap(actions => actions),
    map(action => {
      return {
        type: `[${this.prefix}] ${action.type}`,
        payload: Entity.fromSnapshot<Invoice>(action.payload.doc)
      };
    }),
    takeUntil(this.store.onComplete(this.actions.QUERYCOMPANYID))
  );

  protected get actions() {
    return InvoiceActions;
  }
}
