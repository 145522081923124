import {AbstractAction} from '../../utils/abstract-action';
import {Injectable, Type} from '@angular/core';
import {AbstractEffects} from '../../utils/abstract-effects';
import {Actions, Effect} from '@ngrx/effects';
import {UserResource} from '../../resources/user.resource';
import {Observable} from 'rxjs';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {Action} from '@ngrx/store';
import {AbstractStore} from '../../utils/abstract-store';
import {UserRole} from '../../models/user-role';
import {StoreService} from '../../services/store.service';

class CUserRoleStore extends AbstractStore<UserRole> {

  prefix(): string {
    return 'UserRole';
  }

  effects(): Type<AbstractEffects<UserRole>> {
    return UserRoleEffects;
  }

}

@Injectable()
class UserRoleEffects extends AbstractEffects<UserRole> {

  constructor(protected actions$: Actions, private userResource: UserResource, storeService: StoreService) {
    super(actions$, storeService);
  }

  getResources(): Observable<DocumentChangeAction<UserRole>[]> {
    return this.userResource.getUserRoles();
  }

  get prefix(): string {
    return 'UserRole';
  }

  @Effect()
  get query$(): Observable<Action> {
    return this._query$;
  }

  protected get actions(): AbstractAction<UserRole> {
    return UserRoleStore.actions();
  }
}


export const UserRoleStore = new CUserRoleStore();
