import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ArrayKeysPipe} from './pipes/arrayKeys.pipe';
import {UcfirstPipe} from './pipes/ucfirst.pipe';
import {CompanyResource} from './resources/company.resource';
import {ResourceFormatter} from './resources/resource-formatter.resource';
import {NavbarTitleService} from './services/navbar-title.service';
import {AuthGuard} from './guards/auth.guard';
import {ServerService} from './services/server.service';
import {ServerResource} from './resources/server.resource';
import {ParamReplacePipe} from './pipes/param-replace.pipe';
import {ProductTypeService} from './services/product-type.service';
import {ProductTypeResource} from './resources/product-type.resource';
import {ProductResource} from './resources/product.resource';
import {ProductService} from './services/product.service';
import {UserService} from './services/user.service';
import {UserResource} from './resources/user.resource';
import {PermGuard} from './guards/perm.guard';
import {StripeService} from './services/stripe.service';
import {TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import {LogPipe} from './pipes/log.pipe';
import {CompanyGuard} from './guards/company.guard';
import {InvoiceStatusPipe} from './pipes/invoice-status.pipe';
import {ServerStatusPipe} from './pipes/server-status.pipe';
import {ColorDirective} from './directives/color.directive';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const PROVIDERS = [
  // Services
  NavbarTitleService,
  ServerService,
  ProductTypeService,
  ProductService,
  UserService,
  StripeService,

  // Resources
  CompanyResource,
  ResourceFormatter,
  ServerResource,
  ProductTypeResource,
  ProductResource,
  UserResource,

  // Guards
  AuthGuard,
  CompanyGuard,
  PermGuard

  // States
];

const DECLARATIONS = [
  // Pipes
  ArrayKeysPipe,
  UcfirstPipe,
  ParamReplacePipe,
  LogPipe,
  InvoiceStatusPipe,
  ServerStatusPipe,

  // Directives
  ColorDirective
];

@NgModule({
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
  imports: [
    CommonModule,
    TranslateModule
  ],
  providers: PROVIDERS,
})
export class CoreModule {
}
