import {AbstractAction} from '../../utils/abstract-action';
import {ProductType} from '../../models/product-type';

class CProductTypeActions extends AbstractAction<ProductType> {
  get prefix(): string {
    return 'ProductType';
  }
}

export const ProductTypeActions = new CProductTypeActions();

