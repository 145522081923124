import {Injectable} from '@angular/core';
import {CompanyService} from '../services/company.service';
import {FrontPage} from '../models/front-page';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShopResource {

  constructor(
    private companyService: CompanyService
  ) {}

  getFrontPage() {
    const companyRef = this.companyService.getCurrentCompanyDocument();
    return companyRef.collection('pages').doc<FrontPage>('front-page').snapshotChanges().pipe(catchError((err, caught) => {
      console.log(err);
      return caught;
    }));
  }

  updateFrontPageText(html: string) {
    const companyRef = this.companyService.getCurrentCompanyDocument();
    return companyRef.collection<FrontPage>('pages').doc('front-page').set({text: html});
  }

}
