import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {map, mergeMap, switchMap, takeUntil} from 'rxjs/operators';
import {ServerResource} from '../../resources/server.resource';
import {ServerVersionActions} from '../actions/server-version.actions';
import {Entity} from '../../models/entity';
import {ServerVersion} from '../../models/server-version.model';
import {StoreService} from '../../services/store.service';

@Injectable()
export class ServerVersionEffects {


  @Effect()
  query$: Observable<Action> = this.actions$.pipe(
    ofType(ServerVersionActions.QUERY),
    switchMap(action => {
      return this.serverResource.getServerVersions();
    }),
    mergeMap(actions => actions),
    map(action => {
      return {
        type: `[ServerVersion] ${action.type}`,
        payload: Entity.fromSnapshot<ServerVersion>(action.payload.doc)
      };
    }),
    takeUntil(this.storeService.onComplete(ServerVersionActions.QUERY))
  );

  constructor(private actions$: Actions, private serverResource: ServerResource, private storeService: StoreService) { }
}
