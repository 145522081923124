import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SweetAlertOptions, SweetAlertType} from 'sweetalert2';
import {SwalComponent} from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-sweet-alert',
  templateUrl: './sweet-alert.component.html',
  styleUrls: ['./sweet-alert.component.scss']
})
export class SweetAlertComponent {

  @Input() type: SweetAlertType = 'info';

  @Input() title: string;
  @Input() body: string;
  @Input() customOptions: SweetAlertOptions;

  @Output() confirm = new EventEmitter();
  @Output() cancel = new EventEmitter();

  @ViewChild('swalComponent', {static: false}) swalComponent: SwalComponent;

  get swalOptions(): SweetAlertOptions {

    const defaultOptions: SweetAlertOptions = {
      title: this.title,
      html: this.body,
      type: this.type,
    };

    return {...defaultOptions, ...this.customOptions};
  }

  show() {
    this.swalComponent.show();
  }
}
