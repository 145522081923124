import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Invoice} from '../models/invoice';
import {Entity} from '../models/entity';
import {AbstractService} from '../utils/abstract-service';
import {InvoiceSelectors, InvoiceState} from '../store/selectors/Invoice.selectors';
import {Store} from '@ngrx/store';
import {StoreService} from './store.service';
import {InvoiceActions} from '../store/actions/invoice.actions';
import {InvoiceResource} from '../resources/invoice.resource';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends AbstractService<InvoiceState> {

  constructor(
    private store: Store<InvoiceState>,
    private storeService: StoreService,
    private invoiceResource: InvoiceResource,
  ) {
    super(store, storeService);
  }

  getUserInvoices(userId): Observable<Entity<Invoice>[]> {
    this.dispatch(InvoiceActions.QueryUserId(userId));
    return this.store.select(InvoiceSelectors.getInvoiceByUserId(userId));
  }

  getInvoice(invoiceId): Observable<Entity<Invoice>> {
    const data = this.dispatch(InvoiceActions.QueryInvoiceId(invoiceId));
    return this.store.select(InvoiceSelectors.getInvoiceByInvoiceId(invoiceId));
  }

  getCompanyInvoices(companyId): Observable<Entity<Invoice>[]> {
    this.dispatch(InvoiceActions.QueryCompanyId());
    return this.store.select(InvoiceSelectors.getInvoiceByCompanyId(companyId));
  }

  cancelInvoice(invoiceId) {
    this.invoiceResource.cancelInvoice(invoiceId);
  }

}
