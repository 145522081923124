import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Entity} from '../../../../core/models/entity';
import {Invoice} from '../../../../core/models/invoice';
import {Company} from '../../../../core/models/company';
import {User} from '../../../../core/models/user';
import {CompanyService} from '../../../../core/services/company.service';
import {InvoiceService} from '../../../../core/services/invoice.service';
import {UserService} from '../../../../core/services/user.service';
import {StripeService} from '../../../../core/services/stripe.service';
import {SweetAlertOptions} from 'sweetalert2';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  @Input()
  public invoiceId: string;

  $invoice: Observable<Entity<Invoice>>;
  $company: Observable<Entity<Company>>;
  $user: Observable<Entity<User>>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyService,
    private invoiceService: InvoiceService,
    private userService: UserService,
    private stripe: StripeService
  ) { }

  ngOnInit() {
    this.$invoice = this.invoiceService.getInvoice(this.invoiceId).pipe(
      tap(invoice => {
        if (invoice) {
          console.log(invoice.data);
          this.$user = this.userService.getUserWithPrivateData(invoice.data.user.id);
        }
      })
    );
    this.$company = this.companyService.getCurrentCompanyAsync();
  }

  cancelInvoiceAlertOptions(): SweetAlertOptions {
    return {
      confirmButtonText: 'Yes, cancel',
      cancelButtonText: 'No, do not cancel',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#fd397a',
      cancelButtonColor: '#1dc9b7',
    };
  }

  cancelInvoice() {
    console.log('cancel');
    this.invoiceService.cancelInvoice(this.invoiceId);
  }

  print() {
    window.print();
  }

  openPaymentModal(invoice: Entity<Invoice>) {
    this.stripe.openPaymentModal(invoice.data.total, async (token) => {
      await this.stripe.invoicePayment(invoice.id, token);

      this.stripe.closePaymentModal();
    });

  }

}
