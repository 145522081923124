import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../../core/services/authentication.service';
import {AuthUser} from '../../../../core/models/auth-user';
import {filter, map, share} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Entity} from '../../../../core/models/entity';
import {Company} from '../../../../core/models/company';
import {CompanyService} from '../../../../core/services/company.service';

@Component({
  selector: 'app-header-frontpage',
  templateUrl: './header-frontpage.component.html',
  styleUrls: ['./header-frontpage.component.scss']
})
export class HeaderFrontpageComponent implements OnInit {

  $user: Observable<AuthUser>;

  $company: Observable<Entity<Company>>;

  constructor(
    private auth: AuthenticationService,
    private companyService: CompanyService,
  ) { }

  ngOnInit(): void {
    this.$company = this.companyService.getCurrentCompanyAsync();

    this.$user = this.auth.getAuthUser().pipe(
      filter(v => !!v),
      map(v => v.data),
      share(),
    );
  }

  logout() {
    this.auth.logout();
  }
}
