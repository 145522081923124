import {Action} from '@ngrx/store';
import {ServerStatus} from '../../models/server-status.model';

export module ServerStatusActions {
  export const QUERY = '[ServerStatus] query server status';

  export const ADDED = '[ServerStatus] added';
  export const MODIFIED = '[ServerStatus] modified';
  export const REMOVED = '[ServerStatus] removed';

  export const UPDATE = '[ServerStatus] update';
  export const SUCCESS = '[ServerStatus] update success';


  // Initial query
  export class Query implements Action {
    readonly type = QUERY;

    constructor(
      public id: string,
      public daemonUrl: string,
    ) {
    }
  }

  // AngularFire2 StateChanges
  export class Added implements Action {
    readonly type = ADDED;

    constructor(public payload: ServerStatus) {
    }
  }

  export class Modified implements Action {
    readonly type = MODIFIED;

    constructor(public payload: ServerStatus) {
    }
  }

  export class Removed implements Action {
    readonly type = REMOVED;

    constructor(public payload: ServerStatus) {
    }
  }


  // Run a Firestore Update
  export class Update implements Action {
    readonly type = UPDATE;

    constructor(
      public id: string,
      public payload: {ip: string, newStatus: string},
    ) {
    }
  }

  export class Success implements Action {
    readonly type = SUCCESS;

    constructor() {
    }
  }

  export type Actions = Query | Added | Modified | Removed | Update | Success;
}

