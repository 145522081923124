import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {AuthUserActions} from '../actions/user-auth.actions';
import {UserResource} from '../../resources/user.resource';
import {filter, map, mergeMap, switchMap, takeUntil} from 'rxjs/operators';
import {AuthenticationService} from '../../services/authentication.service';
import {StoreService} from '../../services/store.service';

@Injectable()
export class AuthUserEffects {

  constructor(
    protected actions$: Actions,
    private userResource: UserResource,
    private authentication: AuthenticationService,
    private storeService: StoreService
  ) { }

  @Effect()
  query$: Observable<Action> = this.actions$.pipe(
    ofType(AuthUserActions.QUERY),
    switchMap(auth => {
      return this.authentication.getAuth();
    }),
    filter(auth => !!auth),
    switchMap(auth => {
      return this.userResource.getAuthUser(auth.user).pipe(
        map(v => [v])
      );
    }),
    mergeMap(actions => actions),
    map(action => {
      return {
        type: `[AuthUser] ${action.type}`,
        payload: action.payload
      };
    }),
    takeUntil(this.storeService.onComplete(AuthUserActions.QUERY))
  );
}
