import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {AbstractEffects} from '../../utils/abstract-effects';
import {DocumentChangeAction} from '@angular/fire/firestore';
import {ProductType} from '../../models/product-type';
import {ProductTypeActions} from '../actions/product-type.actions';
import {ProductTypeResource} from '../../resources/product-type.resource';
import {AbstractAction} from '../../utils/abstract-action';
import {StoreService} from '../../services/store.service';

@Injectable()
export class ProductTypeEffects extends AbstractEffects<ProductType> {

  constructor(protected actions$: Actions, private productTypeResource: ProductTypeResource, storeService: StoreService) {
    super(actions$, storeService);
  }

  getResources(): Observable<DocumentChangeAction<ProductType>[]> {
    return this.productTypeResource.getProductTypes();
  }

  get prefix(): string {
    return 'ProductType';
  }

  @Effect()
  get query$(): Observable<Action> {
    return this._query$;
  }

  protected get actions(): AbstractAction<ProductType> {
    return ProductTypeActions;
  }
}
