import {Injectable, NgZone} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {CompanyService} from '../services/company.service';
import {catchError, map, tap} from 'rxjs/operators';

@Injectable()
export class CompanyGuard implements CanActivate {

  constructor(private router: Router,
              private ngZone: NgZone,
              private companyService: CompanyService) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.companyService.getCurrentCompanyAsync().pipe(map(company => !!company.id), catchError(err => {
      return of(false);
    }));
  }
}
