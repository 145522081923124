import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paramReplace'
})
export class ParamReplacePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (!args) {
      return value;
    }

    for (const k of Object.keys(args)) {
      const v = args[k];
      value = value.replace(new RegExp(':' + k), v);
    }

    return value;
  }

}
