import {Action} from '@ngrx/store';
import {Server} from '../../models/server.model';
import {ServerUpdateAbleFields} from '../../../content/modules/server/models/server-update-able-fields.model';
import {ServerReducers} from '../reducers/server.reducers';

export module ServerActions {
  export const QUERY = '[Server] query servers';

  export const ADDED = '[Server] added';
  export const MODIFIED = '[Server] modified';
  export const REMOVED = '[Server] removed';

  export const UPDATE = '[Server] update';
  export const SUCCESS = '[Server] update success';


// Initial query
  export class Query implements Action {
    readonly type = QUERY;

    constructor() {
    }
  }

// AngularFire2 StateChanges
  export class Added implements Action {
    readonly type = ADDED;

    constructor(public payload: ServerReducers.EntityType) {
    }
  }

  export class Modified implements Action {
    readonly type = MODIFIED;

    constructor(public payload: ServerReducers.EntityType) {
    }
  }

  export class Removed implements Action {
    readonly type = REMOVED;

    constructor(public payload: ServerReducers.EntityType) {
    }
  }


// Run a Firestore Update
  export class Update implements Action {
    readonly type = UPDATE;

    constructor(
      public id: string,
      public changes: Partial<ServerUpdateAbleFields>,
    ) {
    }
  }

  export class Success implements Action {
    readonly type = SUCCESS;

    constructor() {
    }
  }

  export type Actions = Query | Added | Modified | Removed | Update | Success;
}

