// Angular
import {
  ChangeDetectionStrategy, Component, OnInit
} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthUser} from '../../../../../core/models/auth-user';
import {AuthenticationService} from '../../../../../core/services/authentication.service';
import {filter, map} from 'rxjs/operators';

@Component({
  selector: 'kt-menu-horizontal',
  templateUrl: './menu-horizontal.component.html',
  styleUrls: ['./menu-horizontal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuHorizontalComponent implements OnInit {

  $user: Observable<AuthUser>;


  constructor(
    private auth: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.$user = this.auth.getAuthUser().pipe(
      filter(v => !!v),
      map(v => v.data)
    );
  }

  logout() {
    this.auth.logout();
  }

}
