import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Observable} from 'rxjs';
import {Action} from '@ngrx/store';
import {map, mergeMap, switchMap, takeUntil} from 'rxjs/operators';
import {CartActions} from '../actions/cart.actions';
import {CartResource} from '../../resources/cart.resource';
import {Entity} from '../../models/entity';
import {CartProduct} from '../../models/cart-product';
import {StoreService} from '../../services/store.service';

@Injectable()
export class CartEffects {

  @Effect()
  query$: Observable<Action> = this.actions$.pipe(
    ofType(CartActions.QUERY),
    map((action: CartActions.Query) => action),
    switchMap(action => {
      return this.cartResource.getCart(action.user);
    }),
    mergeMap(actions => actions),
    map(action => {
      return {
        type: `[Cart] ${action.type}`,
        payload: Entity.fromSnapshot<CartProduct>(action.payload.doc)
      };
    }),
    takeUntil(this.storeService.onComplete(CartActions.QUERY))
  );

  @Effect()
  update$: Observable<Action> = this.actions$.pipe(
    ofType(CartActions.UPDATE),
    map((action: CartActions.Update) => action),
    switchMap(data => {
      return this.cartResource.editCartProduct(data.productId, data.user, data.changes);
    }),
    map(() => new CartActions.Success())
  );

  @Effect()
  remove$: Observable<Action> = this.actions$.pipe(
    ofType(CartActions.REMOVE),
    map((action: CartActions.Remove) => action),
    switchMap(data => {
      return this.cartResource.removeCartProduct(data.productId, data.user);
    }),
    map(() => new CartActions.Success())
  );

  constructor(private actions$: Actions, private cartResource: CartResource, private storeService: StoreService) { }
}
