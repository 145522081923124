import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../core/guards/auth.guard';
import {FrontpageLayoutComponent} from '../layout/frontpage-layout/frontpage-layout.component';
import {PermGuard} from '../../core/guards/perm.guard';
import {CompanyGuard} from '../../core/guards/company.guard';
import {DashboardMetronicComponent} from '../layout/dashboard-metronic-layout/dashboard-metronic/dashboard-metronic.component';

const routes: Routes = [

  {
    path: '',
    component: FrontpageLayoutComponent,
    canActivate: [CompanyGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./frontpage/front-page.module').then(m => m.FrontPageModule)
      },
      {
        path: 'login',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },
  {
    path: 'dashboard',
    component: DashboardMetronicComponent,
    canActivate: [CompanyGuard, AuthGuard, PermGuard],
    children: [
      {
        path: '',
        redirectTo: 'servers',
        pathMatch: 'full'
      },
      {
        path: 'servers',
        loadChildren: () => import('./server/server.module').then(m => m.ServerModule)
      },
      {
        path: 'products',
        loadChildren: () => import('./product/product.module').then(m => m.ProductModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'company',
        loadChildren: () => import('./company/company.module').then(m => m.CompanyModule)
      },
      {
        path: 'shop',
        loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule)
      }
    ]
  },
  {
    path: 'invitation',
    loadChildren: () => import('./company-register/company-register.module').then(m => m.CompanyRegisterModule)
  },
  {
    path: '404',
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
  },
  {
    path: 'error/:type',
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ModulesRoutingModule {
}
