import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'log'
})
export class LogPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (args) {
      console.log(args[0], value);
    } else {
      console.log(value);
    }
    return value;
  }

}
