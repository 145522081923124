import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModulesRoutingModule } from './modules.routing.module';
import {LayoutModule} from '../layout/layout.module';
import {CoreModule} from '../../core/core.module';

@NgModule({
  imports: [
    CommonModule,
    ModulesRoutingModule,
    LayoutModule,
    CoreModule,
  ],
})
export class ModulesModule {
}
